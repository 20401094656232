import React, { useState, useEffect,useRef } from 'react';
import moment from 'moment';
import image from '../../../../constant/image';
import { Row, Col, ProgressBar, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { apiCall, chunkUpload } from '../../../../action/loginAction';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';
import ReactPaginate from 'react-paginate';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './MediaGrid.css'
import { act } from 'react-dom/test-utils';
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import Badge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import { getShareLink } from '../../../../constant/getShareLink';
import { generatePath } from 'react-router-dom';
const MediaGrid = (props) => {
    const dispatch = useDispatch();
    //feedData data
    const chunkSize = 1048576 * 17;
    const [isImageUpload, setIsImageUpload] = useState(false);
    const [isAudioUpload, setIsAudioUpload] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [feedCategory, setFeedCategory] = useState(props.feedData.category_title)
    const [feedData, setFeedData] = useState(props.feedData);
    const [selfLike, setSelfLike] = useState(props.feedData.self_like);
    const [likeCount, setLikeCount] = useState(props.feedData.like_count)
    const [engagmentValue, setEngagmentValue] = useState(props.feedData.total_engagement)
    const [modalShow, setModalShow] = useState(false);
    const email = localStorage.getItem('email');
    const [counter, setCounter] = useState(1)
    const [showProgress, setShowProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)
    const [fileToBeUpload, setFileToBeUpload] = useState({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
    const [tagUserModalShow, setTagUserModalShow] = useState(false);
    const [commentModalShow, setCommentModalShow] = useState(false);
    const [likeList, setLikeList] = useState([]);
    const [mediaId, setMediaId] = useState(null)
    const progressInstance = <ProgressBar style={{ height: '100%' }} animated now={progress} label={`${progress.toFixed(1)}%`} />;
    const [commentList, setCommentList] = useState([]);
    const [typeOfComment, setTypeOfComment] = useState("post");
    const [pendingUserPage, setPendingUserPage] = useState(1);
    const [fileSize, setFileSize] = useState(0)
    const [isPostId, setIsPostId] = useState('');
    const [totalFeedLike, setTotalFeedLike] = useState({});
    const [isCommentText, setIsCommentText] = useState('');
    const [parentsData, setParentsName] = useState({ name: '', parentId: null, index: '' });
    const [tagUserList, setTagUserList] = useState([]);
    const [sharePostModel, setSharePostModel] = useState(false)
    const [sharePostModelData, setSharePostModelData] = useState('');
    // time using moment 
    let timeDifferenceNew = moment.utc(feedData.date).local().startOf('seconds').fromNow();
    // count show data 
    const fullName = localStorage.getItem('fullName');
    const profilePicture = localStorage.getItem('profile_picture');
    const [isUploadText, setIsUploadText] = useState('');
    const [sharePostType, setSharePostType] = useState('General');
    const [channelsData, setChannelsData] = useState([]);
    const [selectChannel, setSelectChannel] = useState({});
    const [oldOpenId, setOldOpenId] = useState('');
    const [editComment, setEditComment] = useState(false);
    const [isEditCommentText, setIsEditCommentText] = useState('');
    const [editCommentId, setEditCommentId] = useState({ parentId: '', childId: '', commentID: '' });
    const loginRole = localStorage.getItem('role');
    const [editPostModel, setEditPostModel] = useState(false)
    const [editPostData, setEditPostData] = useState('');
    const [attributeValue, setAttributeValue] = useState(props.isAttributeSelected)
    const userId = localStorage.getItem('user_id');
    const [mideaList, setMideaList] = useState([]);
    const [mideaIdList, setMideaIdList] = useState([]);
    // edit post
    const [isPostValue, setIsPostValu] = useState('General');
    const postValues = ['General', 'Important']
    const [selectedAttribute, setSelectedAttribute] = useState({});
    const [taggedUsers, setTaggedUsers] = useState([]);

    const [postAttribute, setPostAttribute] = useState([]);
    const [editFiltermodalShow, setEditFiltermodalShow] = useState(false);
    const [provinceCityData, setProvinceCityData] = useState([]);
    const [primarySpeciality, setPrimarySpeciality] = useState([]);
    const [secondarySpeciality, setSecondarySpeciality] = useState([]);
    const [provinceCity, setProvinceCity] = useState([]);
    const [district, setDistrict] = useState([]);
    const [age, setAge] = useState([]);
    const [placeOfWork, setPlaceOfWork] = useState([]);
    const [provinceData, setProvinceData] = useState([]);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [selectUnivercityList, setSelectUnivercityList] = useState([]);
    const [userType, setUserType] = useState('')
    const [univercityList, setUnivercityList] = useState([]);
    const [practitionerType, setPractitionerType] = useState('surgeon')
    const [registrationPageData, setRegistrationPageData] = useState([])
    const [specialitiesList, setSpecialitiesList] = useState([]);
    const initialRender = useRef(true);

    const [topicTagModel, setTopicTagModel] = useState(false)
    const [topicTagList, setTopicTagList] = useState([]);
    const [selectedTopicTags , setSelectedTopicTags] = useState([]);
    const [renderFirstTime , setRenderFirsrTime] = useState(true)
    const [showSharePostData, sethowSharePostData] = useState(false)

    const [showClickData, setShowClickData] = useState(false)

    const { isSearchActive } = useSelector(state => state.feedData);
    const { targetingPostId } = useSelector(state => state.feedData);
    const [showIndividualPostDetails, setShowIndividualPostDetails] = useState(false);
    const [indivisualPostDetails , setIndividualPostDetails] = useState([])
    const [individualMediaId, setIndividualMediaId] = useState(null)
    useEffect(() => {
        setFeedData(props.feedData)
        setAttributeValue(props.isAttributeSelected)
    }, [props])
    // get data
    const kFormatter = (num) => {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }
    useEffect(() => {
        if (fileSize > 0) {
            fileUpload(counter);
        }
    }, [fileToBeUpload, progress])
    // like post
    const likePost = async (id, val) => {
        setSelfLike(!val)
        if(renderFirstTime == true){
            val === true ? setLikeCount(feedData.like_count - 1) : setLikeCount(feedData.like_count + 1)
            val === true ? setEngagmentValue(feedData.total_engagement - 1) : setEngagmentValue(feedData.total_engagement + 1)
        }else{
            val === true ? setLikeCount(likeCount - 1) : setLikeCount(likeCount + 1)
            val === true ? setEngagmentValue(engagmentValue - 1) : setEngagmentValue(engagmentValue + 1)
        }
        var data = {
            post_id: id
        };
        setRenderFirsrTime(false)
        let res = await dispatch(apiCall(data, '/user/like'));
        if (res.data.message.MSG_CODE === 100111 || res.data.message.MSG_CODE === 100121) {
            // getChannelData(1);
        } else if (res.data.message.MSG_CODE === 100610 || res.data.message.MSG_CODE === 100170) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    // add line brk 
    const newlinesTexts = (text, id) => {
        text = text.replace(/\n/g, "<br />")
        let newText = urlify(text)
        return newText;
    }
    // conver hyperlink
    const urlify = (text) => {
        var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#()\[\]\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        let newVal = text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank" >' + url + '</a>';
        })
        return newVal
    }
    // show like post
    const showUserWhoLike = async (postId, pg , isPost) => {
        setIsPostId(postId);
        var data = {
            get_liking_users_data: true,
            //post_id: postId,
            preferred_language: "EN",
            page_number: pg,
            per_page: 10
        }
        if(isPost){
            data.post_id = postId
        }else{
            data.media_id = postId
            
        }
        let res = await dispatch(apiCall(data, '/user/feed'));
        if (res.data.message.MSG_CODE === 100441) {
            setModalShow(true);
            setLikeList(res.data.data);
            setTotalFeedLike(res.data.pagination);
        } else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Post like count Failure',
                timer: 3000
            })
        }
    }
    // show share post
    const showUserWhoshare = async (postId, pg, isPost) => {
        setIsPostId(postId);
        sethowSharePostData(true)
        var data = {
            get_sharing_users_data: true,
            //post_id: postId,
            preferred_language: "EN",
            page_number: pg,
            per_page: 10
        }
        if(isPost){
            data.post_id = postId
        }else{
            data.media_id = postId   
        }
        let res = await dispatch(apiCall(data, '/user/feed'));
        if (res.data.message.MSG_CODE === 100441) {
            setModalShow(true);
            setLikeList(res.data.data);
            setTotalFeedLike(res.data.pagination);
        } else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Post like count Failure',
                timer: 3000
            })
        }
    }

    //show click post data
    const showUserWhoClick = async (postId, pg) => {
        console.log("click")
        setIsPostId(postId);
        setShowClickData(true)
        var data = {
            get_clicking_users_data: true,
            post_id: postId,
            preferred_language: "EN",
            page_number: pg,
            per_page: 10
        }
        let res = await dispatch(apiCall(data, '/user/feed'));
        if (res.data.message.MSG_CODE === 101011) {
            setModalShow(true);
            setLikeList(res.data.data);
            setTotalFeedLike(res.data.pagination);
        } else if (res.data.message.MSG_CODE === 101110) {
            Swal.fire({
                icon: 'error',
                text: 'Clicking user data retrieval failure',
                timer: 3000
            })
        }
    }
    // model operation
    const closeModel = () => {
        setModalShow(false);
        sethowSharePostData(false);
        setShowClickData(false);
        setLikeList([])
        setTotalFeedLike({})
    }
    //pagination
    const handlePageClick = (data) => {
        let currentPage = data.selected + 1;
        if (showClickData)  {
            showUserWhoClick(isPostId, currentPage)
        }else if (showSharePostData){
            showUserWhoshare(isPostId, currentPage, true)
        }else{
            showUserWhoLike(isPostId, currentPage , true)
        }
        setPendingUserPage(currentPage)
    }
    // getCommentData
    const getComments = async (postId, pg, type,isMedia , mediaId , pcmntId, index ) => {
        var data = {
            // get_comments_on_post: type === 'comment' ? true : false,
            // get_comments_on_comment: type !== 'comment'  ? true : false,
            //post_id: postId,
            page_number: pg,
            per_page: 50,
            parent_comment_id: pcmntId !== undefined ? pcmntId : null,
            // get_comments_on_media : isMedia,
            // media_id : mediaId
        };
        if (isMedia) {
            data.get_comments_on_media = type === 'comment';
            data.media_id = mediaId;
            data.get_comments_on_comment = type !== 'comment';
            setIndividualMediaId(mediaId)
        } else {
            data.get_comments_on_post = type === 'comment';
            data.get_comments_on_comment = type !== 'comment';
            data.post_id = postId;
        }
        let res = await dispatch(apiCall(data, '/user/comment2'));
        if (res.data.message.MSG_CODE === 100471) {
            setCommentModalShow(true);
            if (type === 'comment') {
                let newArra = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    res.data.data[i]['child_comment'] = []
                    res.data.data[i]['isShowOption'] = false;
                    newArra.push(res.data.data[i])
                }
                setCommentList(newArra)
                setTimeout(() => {
                    showOptionListD()
                }, 10);
            } else {
                let childRest = res.data.data
                for (let j = 0; j < childRest.length; j++) {
                    childRest[j]['isShowOption'] = false;
                }
                commentList[index].comments_count = res.data.pagination.total_data.total_child_comment_count;
                commentList[index].child_comment = childRest;
                let newList = commentList;
                setCommentList([])
                setTimeout(() => {
                    setCommentList(newList)
                }, 10);
                setTimeout(() => {
                    showOptionListD()
                }, 40);
            }
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Comment retrieval Failure',
                timer: 3000
            })
        }
    }
    // like comment
    const likeComment = async (id, ind, type) => {
        var data = {
            comment_id: id
        };
        let res = await dispatch(apiCall(data, '/user/like'));
        if (res.data.message.MSG_CODE === 100111 || res.data.message.MSG_CODE === 100121) {
            updateCommentData(ind, type)
        } else if (res.data.message.MSG_CODE === 100610 || res.data.message.MSG_CODE === 100170) {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    // close comment model
    const closeCommentModel = () => {
        setCommentModalShow(false)
        setCommentList([])
        setIsCommentText('')
        cancelBtnComment();
    }
    // change height 
    const changeHeightTextArea = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            textarea.addEventListener("keydown", e => {
                let scroll_height = e.target.scrollHeight;
                textarea.style.height = `${scroll_height}px`;
            });
        }, 10);
    }
    // comment on post
    const commentOnPost = async (id, parentId) => {
        var data = {
            add_comment: true,
            target: "post",
            post_id: id,
            text: isCommentText,
            comment_type: typeOfComment,
            parent_comment_id: parentId,
        };
        let res = await dispatch(apiCall(data, '/user/comment2'));
        if (res.data.message.MSG_CODE === 100101) {
            setFeedData({ ...feedData, comment_count: feedData.comment_count + 1 })
            setIsCommentText('')
            if (parentId === null) {
                getComments(feedData.post_id, 1, 'comment', false , null)
            } else {
                getComments(feedData.post_id, 1, 'child',false , null , parentsData.parentId, parentsData.index)
            }
            cancelBtnComment()
        } else if (res.data.message.MSG_CODE === 100170 || res.data.message.MSG_CODE === 100200) {
            Swal.fire({
                icon: 'error',
                text: 'comment Update Failure',
                timer: 3000
            })
        }
    }
    // update comment data 
    const updateCommentData = (index, type) => {
        let oldCommentListData = commentList;
        if (type === 'parent') {
            if (oldCommentListData[index].self_like === true) {
                oldCommentListData[index].likes_count -= 1
                oldCommentListData[index].self_like = false
            } else {
                oldCommentListData[index].likes_count += 1
                oldCommentListData[index].self_like = true
            }
            setCommentList([])
            setTimeout(() => {
                setCommentList(oldCommentListData)
            }, 10);
            setTimeout(() => {
                showOptionListD()
            }, 40);
        } else {
            if (oldCommentListData[index].child_comment[0].self_like === true) {
                oldCommentListData[index].child_comment[0].likes_count -= 1
                oldCommentListData[index].child_comment[0].self_like = false
            } else {
                oldCommentListData[index].child_comment[0].likes_count += 1
                oldCommentListData[index].child_comment[0].self_like = true
            }
            setCommentList([])
            setTimeout(() => {
                setCommentList(oldCommentListData)
            }, 10);
            setTimeout(() => {
                showOptionListD()
            }, 40);
        }
    }
    // onClick reply button
    const replyOnComment = (name, id, ind) => {
        setIsCommentText(`${name} ${''}`)
        setParentsName({ name: name, parentId: id, index: ind })
        document.getElementById('commentTextInputBox').focus();
        setTypeOfComment('comment')
        let commentDiv = document.getElementsByClassName('commentDataDiv');
        commentDiv[0].style.height = '90%';
    }
    // onClick cancel button
    const cancelBtnComment = () => {
        setIsCommentText('')
        setParentsName({ name: '', parentId: null, index: '' })
        setTypeOfComment('post')
        document.getElementById('commentTextInputBox').focus();
        let commentDiv = document.getElementsByClassName('commentDataDiv');
        commentDiv[0].style.height = '94%';
        let textArea1 = document.querySelector('#commentTextInputBox');
        textArea1.style.height = `40px`;
    }
    // comment timing
    const timeDifference = (date) => {
        return moment.utc(date).local().startOf('seconds').fromNow();
    }

    const timeDifferenceSheredPost = (date) => {
        return moment.utc(date).local().startOf('seconds').fromNow();
    }
    // delete commet 
    const deleteComment = async (id, index, type, childInd) => {
        var data = {
            remove_comment: true,
            comment_id: id
        };
        let res = await dispatch(apiCall(data, '/user/comment2'));
        let oldCommentListData = commentList;
        let totalComments = oldCommentListData[index].comments_count + 1;
        if (res.data.message.MSG_CODE === 100331) {
            if (type === 'parent') {
                setFeedData({ ...feedData, comment_count: feedData.comment_count - totalComments })
                oldCommentListData.splice(index, 1)
                setCommentList([])
                setTimeout(() => {
                    setCommentList(oldCommentListData)
                }, 10);
                setTimeout(() => {
                    showOptionListD()
                }, 40);
            } else {
                setFeedData({ ...feedData, comment_count: feedData.comment_count - 1 })
                oldCommentListData[index].child_comment.splice(childInd, 1)
                oldCommentListData[index].comments_count -= 1;
                setCommentList([])
                setTimeout(() => {
                    setCommentList(oldCommentListData)
                }, 10);
                setTimeout(() => {
                    showOptionListD()
                }, 40);
            }
        }
    }
    // share post 
    const sharePost = async (data) => {
        var data = {
            is_create: true,
            share_type: "Post",
            root_post_id: data.shared_post === true ? data.share_details.root_post_id : data.post_id,
            parent_post_id: data.post_id,
            share_text: isUploadText,
            post_type: sharePostType === 'General' ? "public" : "channel",
            channel_id: sharePostType === 'Channel' ? selectChannel.channel_id : null,
            tag_users: [],
            topic_tags: [],
        };
        let res = await dispatch(apiCall(data, '/user/share-post'));
        if (res.data.message.MSG_CODE === 100091) {
            closeSharePostModel();
            props.sharePost(true)
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post share failure',
                timer: 3000
            })
        }
    }
    // delete post 
    const deleteEventSwal = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this post",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed === true) {
                props.deletePost({ postID: feedData.post_id, indexOfPost: props.indexOfFeed })
            }
        })
    }
    // open and close model for tag user
    const openUserListModel = async (id) => {
        var data = {
            get_tagged_users_data: true,
            post_id: id,
            preferred_language: "EN",
            page_number: 1,
            per_page: 10
        }
        let res = await dispatch(apiCall(data, '/user/feed'));
        if (res.data.message.MSG_CODE === 100441) {
            setTagUserList(res.data.data);
            setTagUserModalShow(true)
            setTotalFeedLike(res.data.pagination);
        } else if (res.data.message.MSG_CODE === 100500) {
            Swal.fire({
                icon: 'error',
                text: 'Post like count Failure',
                timer: 3000
            })
        }

    }
    const closeUserListModel = () => {
        setTagUserModalShow(false)
    }
    // change height 
    const changeHeightTextAreaShareModel = (id) => {
        setTimeout(() => {
            var textarea = document.querySelector(id);
            textarea && textarea.addEventListener("keydown", e => {
                let scroll_height = e.target.scrollHeight;
                textarea.style.height = `${scroll_height}px`;
            });
        }, 10);
    }
    // open and close share post
    const openSharePostModel = (data) => {
        getChannelList()
        setSharePostModelData(data)
        setSharePostModel(true)
    }
    const closeSharePostModel = () => {
        setSharePostModel(false)
        setSharePostModelData('')
        setIsUploadText('')
        setSharePostType('public')
        setSelectChannel({})
        setChannelsData([])
    }
    // get all channel list
    const getChannelList = async () => {
        var data = {
            get_channels_list: true,
            per_page: 10000,
            page_number: 1,
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data, '/user/channel-admin'));
        if (res.data.message.MSG_CODE === 100351) {
            setChannelsData(res.data.data);
        }
        else if (res.data.message.MSG_CODE === 100680) {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    // edit comment 
    const isEditComment = (text, id, parntId, childid) => {
        setEditComment(true)
        setEditCommentId();
        setEditCommentId({ commentID: id, parentId: parntId, childId: childid === undefined ? '' : childid })
        let commentDiv = document.getElementsByClassName('commentDataDiv');
        commentDiv[0].style.height = '90%';
        setTimeout(() => {
            setIsEditCommentText(`${text}`)
            document.getElementById('editCommentTextInputBox').focus();
        }, 10)
    }
    // edit comment 
    const isCancelEditComment = () => {
        setEditComment(false)
        setIsEditCommentText('')
        setEditCommentId('');
        setIsCommentText('')
        setParentsName({ name: '', parentId: null, index: '' })
        setTypeOfComment('post')
        let commentDiv = document.getElementsByClassName('commentDataDiv');
        commentDiv[0].style.height = '94%';
        setTimeout(() => {
            let textArea1 = document.querySelector('#commentTextInputBox');
            textArea1.style.height = `40px`;
            document.getElementById('commentTextInputBox').focus();
        }, 10)
    }
    const editCommentOnPost = async () => {
        var data = {
            update_comment: true,
            comment_id: editCommentId.commentID,
            text: isEditCommentText,
        };
        let res = await dispatch(apiCall(data, '/user/comment2'));
        if (res.data.message.MSG_CODE === 100101) {
            let oldCommentListData = commentList;
            if (editCommentId.childId === '') {
                oldCommentListData[editCommentId.parentId].text = isEditCommentText;
            } else {
                oldCommentListData[editCommentId.parentId].child_comment[editCommentId.childId].text = isEditCommentText;
            }
            setCommentList([])
            setTimeout(() => {
                setCommentList(oldCommentListData)
            }, 10);
            setTimeout(() => {
                showOptionListD()
            }, 40);
            isCancelEditComment()
        } else if (res.data.message.MSG_CODE === 100170 || res.data.message.MSG_CODE === 100200) {
            Swal.fire({
                icon: 'error',
                text: 'comment Update Failure',
                timer: 3000
            })
        }
    }
    // showoption list
    const showOptionListD = (id) => {
        let inactive = document.querySelectorAll('.optionList')
        inactive.forEach(node => {
            node.style.display = "none";
        })
        if (id !== undefined) {
            if (oldOpenId === id) {
                let active = document.getElementById(id);
                active.style.display = 'none';
                setOldOpenId('')
            } else {
                let active = document.getElementById(id);
                active.style.display = 'block';
                setOldOpenId(id)
            }
        }
    }
    const closeOpenModel = (e) => {
        if (e.target.classList.length > 1) {
            showOptionListD()
        }
    }
    const showAdminData = (role, postID) => {
        if ((loginRole === 'ADMIN' || 'CO-ADMIN') && (role === 'ADMIN'|| 'CO-ADMIN')) {
            if (isSearchActive) {
                let target = {...targetingPostId,postId:postID}
                const {postId} = target
                dispatch({ type: "getTargetingPara",postId});
            }else{
                props.showAdminPost(postID)
            }
        }
    }
    // edit feed data
    const editFeedPost = (data) => {
        console.log(data , "----")
        if (data.filters) {

            data.filters.user_type ? data.filters.user_type.length >1 ? setUserType('BOTH'): data.filters.user_type.length < 1 ?setUserType('') :setUserType(data.filters.user_type[0]):setUserType('')
            setProvinceCity(data.filters.province_city)
            setDistrict(data.filters.district)
            setMinValue(data.filters.age[0])
            setMaxValue(data.filters.age[1])
            setPlaceOfWork(data.filters.place_of_work)
            getRegistrationPageData(data)
            setTaggedUsers(data.tag_users)
        }
        getAttributesData(1,data.category_id)
        setEditPostData(data)

        // setSelectedTopicTags(data.topic_tags)
        changeTopicTagObject(data)
        setMideaList([...data.media_data]);
        data.is_important === false ? setIsPostValu('General'):setIsPostValu('Important')
        let mediaIDList = []
        for (let i in data.media_data) {
            mediaIDList.push(data.media_data[i]._id)
        }
        setMideaIdList([...mediaIDList])
        setTimeout(()=>{
            setEditPostModel(true)
            
        },100)
    } 
    const closeEditPostModel = () => {
        setEditPostModel(false);
        setShowProgress(false)
        setProgress(0)
        setCounter(1)
        setEditPostData('')
        setMideaList([])
        setMideaIdList([])
        setMediaId(null)
        setIsPostValu('General')
    }
    // get attributes
    const getAttributesData = async (pg,id) => {
        var data = {
            is_get: true,
            content_language: 'EN',
            per_page: 10,
            page_number: pg
        };
        let res = await dispatch(apiCall(data, '/user/admin-category'));
        if (res.data.message.MSG_CODE === 100661) {
            setPostAttribute(res.data.data);
            let AttributesList = res.data.data
            let matchAttribute = AttributesList.find(obj => {
                return obj.category_id === id
              })
              matchAttribute ? setSelectedAttribute(matchAttribute) :setSelectedAttribute({})
        }
        else {
            Swal.fire({
                icon: 'error',
                text: 'Something went wrong please try again later',
                timer: 3000
            })
        }
    }
    //get all data 
    const getRegistrationPageData = async (editData) => {
        var data = {
            preferred_language: 'EN',
        };
        let res = await dispatch(apiCall(data, '/user/get-registration-page-info'));
        if (res.data.message.MSG_CODE === 100281) {
            setRegistrationPageData(res.data.data)
            let newSpecArray = [];
            for (let i = 0; i < res.data.data.specialities.length; i++) {
                newSpecArray.push({ 'label': res.data.data.specialities[i].label_name, 'value': res.data.data.specialities[i].identifier_id })
            }
            setSpecialitiesList(newSpecArray);
            if (newSpecArray.length === editData.filters.primary_speciality ) {
                setPrimarySpeciality([])
            }
            if (newSpecArray.length === editData.filters.secondary_speciality ) {
                setSecondarySpeciality([])
            }
            let primarySpeciality = newSpecArray.filter(o1 => editData.filters.primary_speciality.some(o2 => o1.value === o2.identifier_id));
            setPrimarySpeciality(primarySpeciality)
            let secondrySpeciality = newSpecArray.filter(o1 => editData.filters.secondary_speciality.some(o2 => o1.value === o2.identifier_id));
            setSecondarySpeciality(secondrySpeciality)
           
            // University
            let newUniArray = [];
            for (let i = 0; i < res.data.data.universities.length; i++) {
                newUniArray.push({ 'label': res.data.data.universities[i].label_name, 'value': res.data.data.universities[i].identifier_id })
            }
            setUnivercityList(newUniArray);

            let universitiesList = newUniArray.filter(o1 => editData.filters.universities.some(o2 => o1.value === o2.identifier_id));
            setSelectUnivercityList(universitiesList)
            //province
            let newarray = res.data.data.provinces;
            newarray = Object.keys(newarray);
            setProvinceData(newarray);
            onProvinceClick(editData.filters.province_city,res.data.data)

        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post Update Failure',
                timer: 3000
            })
        }
    }
    //upload data
    const uploadFile = async (file, type) => {
        let arrayMedia = [];
        let arrayMediaId = [];
        let isUploadingCompletedSuccessfully = false
        for (let i = 0; i < file.length; i++) {
            let convertString = await convertBase64(file[i]);
            let base64Array = convertString.split(",");
            let base64 = base64Array[1];
            var data = {
                email: email,
                file_path: "post",
                file_data: base64,
                file_name: file[i].name,
            };
            let res = await dispatch(apiCall(data, '/user/upload'));
            if (res.data.message.MSG_CODE === 100231) {
                arrayMedia.push(res.data.data)
                arrayMediaId.push(res.data.data.media_id)
            } else if (res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Not valid extension please upload again (accept only JPEG, PNG, JPG)',
                    timer: 3000
                })
                break
            } else if (res.data.message.MSG_CODE === 100580 || res.data.message.MSG_CODE === 100010 || res.data.message.MSG_CODE === 100270) {
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please upload again',
                    timer: 3000
                })
                break
            }
            if (i === file.length - 1) {
                isUploadingCompletedSuccessfully = true
            }
        }
        if (isUploadingCompletedSuccessfully === true) {
            setMideaList([...mideaList, ...arrayMedia]);
            setMideaIdList([...mideaIdList, ...arrayMediaId]);
        }
    }
    // upload video in chunks
    const fileUpload = (counter) => {
        setCounter(counter + 1);
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk)
        }
    }
    // base64 conversion
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = (() => {
                resolve(fileReader.result);
            });
            fileReader.onerror = ((error) => {
                reject(error)
            });
        });
    };

    const uploadCompleted = async (val) => {
        let media_id = val;
        const formData = new FormData();
        formData.append('file_upload', 1);
        formData.append('filename', fileToBeUpload.name);
        formData.append('media_id', mediaId === null ? media_id : mediaId);
        const res = await dispatch(chunkUpload(formData));
        if (res.data.message.MSG_CODE === 100591) {
            checkUploadingStatus(media_id)
        }
    }
    const checkUploadingStatus = async (val) => {
        let media_id = val;
        const formData = new FormData();
        formData.append('get_media_details', 1);
        formData.append('filename', fileToBeUpload.name);
        formData.append('media_id', mediaId === null ? media_id : mediaId);
        const res = await dispatch(chunkUpload(formData));

        if (res.data.message.MSG_CODE === 100601) {
            if (res.data.data.upload_status === 'IN PROGRESS') {
                setTimeout(() => {
                    checkUploadingStatus(media_id)
                }, "3000")
            } else if (res.data.data.upload_status === 'SUCCESS') {
                setProgress(100);
                let arrayMedia = [];
                arrayMedia.push(res.data.data)
                setMideaList([...mideaList, ...arrayMedia]);
                if (mediaId === null) {
                    setMideaIdList([...mideaIdList, media_id]);

                } else {
                    setMideaIdList([...mideaIdList, mediaId]);

                }
            } else if (res.data.data.upload_status === 'FAILURE') {
                setShowProgress(false)
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }
        }
    }
    const empty = () => {
        setFeedData([]);
        setIsUploadText('');
        setIsImageUpload(false);
        setIsAudioUpload(false);
        setIsFileUpload(false);
        setShowProgress(false);
    }
    const uploadChunk = async (chunk) => {
        try {
            const formData = new FormData();
            formData.append('file', chunk);
            formData.append('chunk_write', 1);
            formData.append('filename', fileToBeUpload.name);
            formData.append('path', 'channel');
            formData.append('chunk_index', counter);
            formData.append('total_chunks', chunkCount);
            formData.append('media_id', mediaId);
            let res = await dispatch(chunkUpload(formData));
            if (res.data.message.MSG_CODE === 100581) {
                setBeginingOfTheChunk(endOfTheChunk);
                setEndOfTheChunk(endOfTheChunk + chunkSize);
                setMediaId(res.data.data.media_id)
                if (counter === chunkCount) {
                    await uploadCompleted(res.data.data.media_id);
                } else {
                    var percentage = (counter / chunkCount) * 100;
                    setProgress(percentage);
                }
            } else {
                empty();
                Swal.fire({
                    icon: 'error',
                    text: 'Something went wrong please try again!',
                    timer: 4000
                })
            }

        } catch (error) {
            console.log('error', error)
        }
    }
    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }
    const getFileContext = (e) => {
        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize === 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToBeUpload(_file)
        e.target.value = null;
    }
    const removeMedia = (index) => {
        mideaList.splice(index, 1)
        mideaIdList.splice(index, 1)
        setMideaList([...mideaList])
        setMideaIdList([...mideaIdList])
    }
    // edit post 
    const editPost = async (postData) => {
        let scndSpecialityList = getSpecialityList(secondarySpeciality);
        let primSpecialityList = getSpecialityList(primarySpeciality);
        let universittyList = getSpecialityList(selectUnivercityList);
        let userList = getUserListtype(userType);
        if (!postData.shared_post) {
            var data = {
                is_edit: true,
                post_id: postData.post_id,
                text: postData.text,
                media: mideaIdList,
                tag_users: postData.tag_users,
                topic_tags : topicTagList,
                //topic_tags: postData.topic_tags,
                post_type: postData.post_type,
                is_sponsored: taggedUsers.length > 0 ? true : false,
                is_important:isPostValue === 'General' ? false : true,
                category_id: selectedAttribute.category_id ? selectedAttribute.category_id : null,
                primary_speciality: primSpecialityList?primSpecialityList:[],
                secondary_speciality: scndSpecialityList?scndSpecialityList:[],
                universities:universittyList?universittyList:[],
                user_type:userList?userList:[],
                province_city: provinceCity?provinceCity:[],
                district: district?district:[],
                age: age?age:[],
                place_of_work: placeOfWork?placeOfWork:[],
            };
        } else {
            var data = {
                is_edit: true,
                post_id: postData.post_id,
                share_text: postData.share_details.share_text,
                tag_users: postData.tag_users,
                //topic_tags: postData.topic_tags,
                topic_tags : topicTagList
            };
        }
        let url = postData.shared_post ? '/user/share-post' : '/user/post'
        let res = await dispatch(apiCall(data, url));
        if (res.data.message.MSG_CODE === 100091) {
            closeEditPostModel();
            if (isSearchActive) {
                dispatch({ type: "getFeedDataEdit"});
            }else{
                props.isEditPost(true);
            }
            Swal.fire({
                icon: 'success',
                text: 'Post edited successfully',
                timer: 3000
            })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Post share failure',
                timer: 3000
            })
        }
    }
    // post value
    const postValueSelected = (item) => {
        if (isPostValue === item) {
            setIsPostValu('General')
        } else {
            setIsPostValu(item)
        }
    }
    // attribute selection
    const attributeSelected = (item) => {
        if (selectedAttribute === item) {
            setSelectedAttribute({})
        } else {
            setSelectedAttribute(item)
        }
    }
    //model operation
    const closeFilterModel = () => {
        setEditFiltermodalShow(false)
    }
    // set user type 
    const setUserTypeData = (val) => {
        setUserType(val)
    }
    // add provice city to city list 
    const onProvinceClick = (val,regPageData) => {
        if (val.length > 0) {
            if (initialRender.current) {
                initialRender.current = false;
            } else {

            }
            setProvinceCity(val)
            let newdata = [];
            let proviceData = [];
            val.forEach((value) => {
                proviceData = registrationPageData.provinces?registrationPageData.provinces[value]:regPageData.provinces[value]
                newdata = [...newdata, ...proviceData];
                proviceData = []
            })
            const uniqueAges = [...new Set(newdata)]
            setProvinceCityData(uniqueAges)
        } else {
            setProvinceCity([])
            setProvinceCityData([])

        }
    }
    // save filter data
    const saveAllFilterData = () => {
        if (minValue > 1 && maxValue > 1) {
            let ageArray = [];
            if (minValue >= 18 && maxValue < 100) {
                ageArray.push(parseInt(minValue))
                ageArray.push(parseInt(maxValue))
                setAge(ageArray)
                setEditFiltermodalShow(false)
            } else {
                Swal.fire({
                    icon: 'error',
                    text: 'Please enter age between 18 to 100',
                    timer: 3000
                })
            }
        } else {
            setAge([])
            setEditFiltermodalShow(false)
        }
    }
    // list formating
    const getSpecialityList = (list) => {
        let newSpecArray = [];
        for (let i = 0; i < list.length; i++) {
            newSpecArray.push({ 'label_name': list[i].label, 'identifier_id': list[i].value })
        }
        return newSpecArray
    }
    // get userList
    const getUserListtype = (type) => {
        let userListArray = [];
        if (type === 'BOTH') {
            userListArray.push('STUDENT')
            userListArray.push('DOCTOR')
        }else if (type === '') {
            userListArray = [];
            return userListArray
        } else{
            userListArray.push(type)
            return userListArray
        }
        return userListArray
    }
    // get share link 
    const generatePostLink = async(postId) =>{
        let resultLink = await getShareLink('post',postId)
        navigator.clipboard
        .writeText(resultLink)
        .then(() => {
            alert("successfully copied");
        })
        .catch(() => {
            alert("something went wrong");
        });
    }
    const changeTopicTagObject = (data) => {
        let newUniArray = [];
        for (let i = 0; i < data.topic_tags.length; i++) {
            newUniArray.push({ 'label': data.topic_tags[i].name, 'value': data.topic_tags[i].topic_tag_id })
        }
        console.log("new array",newUniArray )
     setSelectedTopicTags(newUniArray)
    }
    const topicTagPressed = async() => {
        var data = {
            get_topic_tags_list: true,
            preferred_language: 'EN',
            // per_page:5,
            // page_number:1,
            paginated_result : false,
            keyword : "",
            
        };
        let res = await dispatch(apiCall(data, '/user/topic-tags'));
         console.log("res : ", res.data.data)
         let topicTags = res.data.data
         let newUniArray = [];
            for (let i = 0; i < res.data.data.length; i++) {
                newUniArray.push({ 'label': res.data.data[i].name, 'value': res.data.data[i].topic_tag_id })
            }
            console.log("new array",newUniArray )
         setTopicTagList(newUniArray)
        
        setTopicTagModel(true)
    }
    const onTopicTagDone = () => {
        console.log("selectedTopicTags",selectedTopicTags)
        refactorTopicTag()
        setTopicTagModel(false)
    }
    const refactorTopicTag = () => {
        let newUniArray = [];
            for (let i = 0; i < selectedTopicTags.length; i++) {
                newUniArray.push({ 'name': selectedTopicTags[i].label, 'topic_tag_id': selectedTopicTags[i].value })
            }
            console.log("new array",newUniArray )
         setTopicTagList(newUniArray)
    }
  
    const MultipleSpeciality = selectedTopicTags.map((item, index) => 
            <div key={index} className='me-3 mt-1 mb-2 rounded-3 text-light' style={{whiteSpace: 'pre-wrap',background:'#0096fb'}}>  
                    <span > {item.label}  </span> 
                </div>
            );

    const getIndividualPostDetails = async (postID) => {
        let data = {
            "get_specific_post_data": true,
            "post_id": postID
        }
        let res = await dispatch(apiCall(data , "/user/feed"))
        console.log("res : ", res.data.data)
        setIndividualPostDetails(res.data.data)
    }

    const mediaClick = async (postId) => {
        console.log("media clicked", postId)
        if(feedData.media_data.length > 1){
            await getIndividualPostDetails(postId)
            setShowIndividualPostDetails(true)
        }

    }

    const closeIndividualPostModel = async () => {
        setShowIndividualPostDetails(false)
    }


    return (
        <>
            <div className='uploadedPostMediaViewDiv mt-4 bg-white py-3'>
                <span className='feedcat'>{attributeValue && feedCategory && feedCategory}</span>
                <div className='profileImagePicDivPost d-flex px-2 justify-content-between '>
                    <div className='d-flex' style={{ width: '92%' }}>
                        {
                            feedData.user && feedData.user.profile_picture !==undefined && feedData.user.profile_picture === null ?
                                <span>

                                    <img className='postProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                </span> :
                                <span>
                                    <img className='postProfileImagePic' src={feedData.user.profile_picture} alt='postProfileImagePic' />
                                </span>
                        }
                        <div className='mx-2' style={{ width: '92%' }}>
                            <div className='d-flex align-items-center custom-justify' style={{ flexWrap: 'wrap' }}>
                                <h6 className='mb-1'>{feedData.user.full_name} </h6> 
                                
                                {
                                    feedData.channel_id !== null ?
                                        <div className='mx-1 d-flex align-items-center'> <h6 className='mb-1'>{`-> ${feedData.channel_data.channel_name} channel`}</h6></div> : null
                                }
                                {
                                    feedData.tag_users && feedData.tag_users.length > 0 ?
                                        <div className='mx-1 d-flex align-items-center '>
                                            <div className='d-flex align-items-center mb-1 custom_logo' >
                                                {feedData?.tag_users[0]?.company_logo && <img className='postProfileImagePic1' src={feedData?.tag_users[0]?.company_logo} alt='postProfileImagePic' /> }
                                                <h6 className='mb-0 mr-1' > {feedData.tag_users[0].user_name} </h6>
                                            </div>

                                            {feedData.tag_users.length > 1 ?
                                                <div className='d-flex align-items-center mb-1 mx-2' >
                                                    <span onClick={() => openUserListModel(feedData.post_id)} style={{ cursor: 'pointer' }}>and {feedData.tag_users.length - 1} others</span>
                                                </div> : null
                                            }
                                        </div> : null
                                }
                                   
                                
                            </div>
                            
                           {feedData.is_sponsored > 0 ? <span className='sponsored'>Sponsored</span> : null}<span className='postTiming m-0 p-0'>{timeDifferenceNew}</span>
                        </div>
                    </div>
                    <div className='px-2 d-flex '>
                        {
                            props.permissions.includes('Post Reaction') &&
                            <>
                                <h6 className='mb-1'>
                                    {(((feedData.user.role === 'ADMIN'||feedData.user.role === 'CO-ADMIN') && loginRole === 'ADMIN') || (feedData.user.role === 'CO-ADMIN' && loginRole === 'CO-ADMIN')) && !feedData.shared_post && feedData.post_type !== 'channel' &&
                                        <>
                                       
                                        <span data-toggle="tooltip" data-placement="bottom" title="Targetting parameters">
                                        <img className='targeting_parameter' onClick={() => showAdminData(feedData.user.role, feedData.post_id)} src={image.target} alt='postProfileImagePic' />
                                        </span>
                                        </>
                                    }
                                </h6>
                                {
                                    ((userId === feedData.user_id || loginRole === 'ADMIN') && (feedData.user.role ==='CO-ADMIN'|| feedData.user.role ==='ADMIN')) &&
                                    <div onClick={() => { editFeedPost(feedData) }} style={{ color: 'green', cursor: 'pointer' }} className='px-4'>
                                        <AiIcons.AiFillEdit />
                                    </div>
                                }
                                <span className='deletePost'  style={{ color: 'red', cursor: 'pointer' }} onClick={() => deleteEventSwal()}><AiIcons.AiFillDelete /></span>
                            </>
                        }
                    </div>
                </div>
                <div className='postRightSideDiv'>
                    <div className='pt-2 px-2 postRightSide'>
                        {
                            feedData.shared_post !== true ?
                                <div>
                                    {
                                        feedData.text === null ?
                                            <></> :

                                            <div className='feedDataDiv text-break' dangerouslySetInnerHTML={{ __html: newlinesTexts(feedData.text) }} />
                                    }
                                    {
                                        feedData.topic_tags !== null ?
                                            feedData.topic_tags.map((tag, indexOfTag) => {
                                                return (
                                                    <span key={indexOfTag}>
                                                        <span className='text mx-1 topicTagName'>#{tag.name}</span>
                                                    </span>
                                                )
                                            }
                                            ) : null
                                    }
                                    {
                                        feedData.media_data.length !== 0 ?
                                            feedData.media_data.map((media, ind) => {
                                                {
                                                    return (
                                                        <div key={ind}>{
                                                            media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC" ?
                                                                <div className='postMediaData mt-2' onClick={() => mediaClick(feedData.post_id)}>
                                                                    {
                                                                    feedData.media_data.length > 1 ? 
                                                                    <img className='postImage' src={media.src} alt='postImage' /> : 
                                                                     <a href={media.src} target="_blank" rel="noreferrer">
                                                                    <img className='postImage' src={media.src} alt='postImage' />
                                                                    </a> 
                                                                    }

                                                                </div> : null
                                                        }
                                                            {
                                                                media.type === "video/mp4" ?
                                                                    <div className='postMediaData mt-2' onClick={() => mediaClick(feedData.post_id)} >
                                                                        <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen
                                                                        sandbox="" onClick={() => mediaClick(feedData.post_id)}
                                                                        >
                                                                        </iframe>
                                                                        <div
                                                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '70%', zIndex: 1 }}
                                                                             onClick={() => mediaClick(feedData.post_id)}
                                                                         />
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                media.type === "audio/mpeg" ?
                                                                    <div className='mt-2 postMediaData' onClick={() => mediaClick(feedData.post_id)}>
                                                                        <audio controls className='w-100'>
                                                                            <source src={media.src} type="audio/mp3" />
                                                                            <source src={media.src} type="audio/mpeg" />
                                                                            <source src={media.src} type="audio/mpg " />
                                                                        </audio>
                                                                    </div> : null
                                                            }
                                                            {
                                                                media.type === "application/pdf" ?
                                                                    <div className='mt-2 postMediaData' onClick={() => mediaClick(feedData.post_id)}>
                                                                        <embed src={media.src} className='w-100' height="400px"/>
                                                                        <div
                                                                            style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '70%', zIndex: 1 }}
                                                                             onClick={() => mediaClick(feedData.post_id)}
                                                                         />
                                                                    </div> : null
                                                            }
                                                            {
                                                                media.type === "application/msword" ?
                                                                    <div className='mt-2 postMediaData'  onClick={() => mediaClick(feedData.post_id)}>
                                                                        <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                        <div
                                                                            style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '70%', zIndex: 1 }}
                                                                             onClick={() => mediaClick(feedData.post_id)}
                                                                         />
                                                                    </div> : null
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                            : null
                                    }
                                </div> :
                                <div >
                                    {
                                        feedData.share_details.share_text === null ?
                                            null :
                                            <div dangerouslySetInnerHTML={{ __html: newlinesTexts(feedData.share_details.share_text) }} />
                                    }
                                    <div className='sharePostData'>
                                        {
                                            feedData.root_post_data !== null && !feedData.root_post_data.is_deleted && feedData.root_post_data.is_deleted === false ?
                                                <div className='d-flex' style={{ width: '92%' }}>
                                                    <>
                                                        {
                                                            feedData.root_post_data.user && feedData.root_post_data.user.profile_picture !== undefined && feedData.root_post_data.user.profile_picture === null ?
                                                                <span>
                                                                    <img className='postProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                                </span> :
                                                                <span>
                                                                    <img className='postProfileImagePic' src={feedData.root_post_data.user.profile_picture} alt='postProfileImagePic' />
                                                                </span>
                                                        }
                                                    </>
                                                    <div className='mx-2' style={{ width: '92%' }}>
                                                        <div className='d-flex align-items-center' style={{ flexWrap: 'wrap' }}>
                                                            <h6 className='mb-1'>{feedData.root_post_data.user.full_name}{feedData.root_post_data.is_sponsored && feedData.root_post_data.is_sponsored === true ? <span className='mx-2'>(Sponsored)</span> : null}</h6>
                                                            {
                                                                feedData.root_post_data.channel_name!==undefined && feedData.root_post_data.channel_name !== null ?
                                                                    <div className='mx-1 d-flex align-items-center'> <h6 className='mb-1'>{`-> ${feedData.root_post_data.channel_name} channel`}</h6></div> : null
                                                            }
                                                            {
                                                                feedData.root_post_data.tag_users && feedData.root_post_data.tag_users !== null && feedData.root_post_data.tag_users.length > 0 ?
                                                                    <div className='mx-1 d-flex align-items-center'>
                                                                        <div className='d-flex align-items-center mb-1' >
                                                                            <span className='mb-0 mx-1'> with </span> <h6 className='mb-0' > {feedData.root_post_data.tag_users[0].user_name} </h6>
                                                                        </div>
                                                                        {feedData.root_post_data.tag_users.length > 1 ?
                                                                            <div className='d-flex align-items-center mb-1 mx-2' >
                                                                                <span onClick={() => openUserListModel(feedData.share_details.root_post_id)} style={{ cursor: 'pointer' }}>and {feedData.root_post_data.tag_users.length - 1} others</span>
                                                                            </div> : null
                                                                        }
                                                                    </div> : null
                                                            }
                                                        </div>
                                                        {
                                                            feedData.shared_post  ? 
                                                            <p className='postTiming m-0 p-0'>{timeDifferenceSheredPost(feedData.root_post_data.edit_date)}</p>  : null 
                                                        }
 
                                                    </div>
                                                </div> : null
                                        }
                                        {
                                            feedData.text !== null ?
                                                <>
                                                    {
                                                        feedData.root_post_data.is_deleted !== true ?

                                                            <div dangerouslySetInnerHTML={{ __html: newlinesTexts(feedData.text) }} /> :
                                                            <h5 style={{ textAlign: 'center', color: 'steelblue' }}>
                                                                Post unavailable
                                                            </h5>
                                                    }
                                                </> : null

                                        }
                                        <>

                                        </>
                                        {
                                            feedData.root_post_data.topic_tags !== null &&  feedData.root_post_data.is_deleted !== true ?
                                                feedData.root_post_data.topic_tags.map((tag, indexOfTag) => {
                                                    return (
                                                        <span key={indexOfTag}>
                                                            <span className='text mx-1 topicTagName'>#{tag.name}</span>
                                                        </span>
                                                    )
                                                }
                                                ) : null
                                        }
                                        {
                                            feedData.media_data.length !== 0 &&  feedData.root_post_data.is_deleted !== true ?
                                                feedData.media_data.map((media, ind) => {
                                                    {
                                                        return (
                                                            <div key={ind} onClick={() => mediaClick(feedData.post_id)}>{
                                                                media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC"?
                                                                    <div className='postMediaData mt-2'>
                                                                        { feedData.media_data.length > 1 ? 
                                                                    <img className='postImage' src={media.src} alt='postImage' /> : 
                                                                     <a href={media.src} target="_blank" rel="noreferrer">
                                                                    <img className='postImage' src={media.src} alt='postImage' />
                                                                    </a> }

                                                                    </div> : null
                                                            }
                                                                {
                                                                    media.type === "video/mp4" ?
                                                                        <div className='postMediaData mt-2'>
                                                                            <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen
                                                                            sandbox=""
                                                                            >
                                                                            </iframe>
                                                                            <div
                                                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '70%', zIndex: 1 }}
                                                                             onClick={() => mediaClick(feedData.post_id)}
                                                                         />
                                                                        </div>
                                                                        : null
                                                                }
                                                                {
                                                                    media.type === "audio/mpeg" ?
                                                                        <div className='mt-2 postMediaData' onClick={() => mediaClick(feedData.post_id)}>
                                                                            <audio controls className='w-100'>
                                                                                <source src={media.src} type="audio/mp3" />
                                                                                <source src={media.src} type="audio/mpeg" />
                                                                                <source src={media.src} type="audio/mpg " />
                                                                            </audio>
                                                                        </div> : null
                                                                }
                                                                {
                                                                    media.type === "application/pdf" ?
                                                                        <div className='mt-2 postMediaData'>
                                                                            <embed src={media.src} className='w-100' height="400px" />
                                                                            <div
                                                                            style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '70%', zIndex: 1 }}
                                                                             onClick={() => mediaClick(feedData.post_id)}
                                                                         />
                                                                        </div> : null
                                                                }
                                                                {
                                                                    media.type === "application/msword" ?
                                                                        <div className='mt-2 postMediaData'>
                                                                            <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                            <div
                                                                            style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: '70%', zIndex: 1 }}
                                                                             onClick={() => mediaClick(feedData.post_id)}
                                                                         />
                                                                        </div> : null
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                })
                                                : null
                                        }
                                    </div>
                                </div>
                        }
                        <div className='d-flex mt-2 justify-content-between align-items-center  reactionCountDiv justify-content-between'>
                            <div className='d-flex align-items-center' onClick={() => showUserWhoLike(feedData.post_id, 1, true)}>
                                <img className='reactionCountBtn' src={image.like2Image} alt='reactionCountBtn' />
                                <span className='m-0 p-0'>{kFormatter(renderFirstTime ? feedData.like_count : likeCount )}</span>
                            </div>
                            <div className='d-flex justify-content-center px-2' onClick={() => getComments(feedData.post_id, 1, 'comment', false , null)}>
                                <span className='m-0 p-0'>{kFormatter(feedData.comment_count)}</span>
                                <span className='reactionCountLabel'> Comments</span>
                            </div>
                            <div className='d-flex px-2'  onClick={() => showUserWhoshare(feedData.post_id, 1 , true)}>
                                <span className='m-0 p-0'>{kFormatter(feedData.share_count)}</span>
                                <span className='reactionCountLabel'>Share</span>
                            </div>
                            <div className='d-flex px-2' onClick={() => showUserWhoClick(feedData.post_id, 1)}>
                                <span className='m-0 p-0'>{feedData.view_count}</span>
                                <span className='reactionCountLabel'>Clicks</span>
                            </div>
                        </div>
                        {props.permissions.includes('Post Reaction') && <div className='d-flex mt-2 px-2 justify-content-between reactionOptionDiv'>
                            <div className='d-flex justify-content-center align-items-center reactionOptionDivImg ' onClick={() => likePost(feedData.post_id, selfLike)} >
                                <img className='reactionBtn' src={image.likeImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName' style={{ color: selfLike === true ? 'blue' : '' }}>Like</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-center reactionOptionDivImg' onClick={() => getComments(feedData.post_id, 1, 'comment', false , null)}>
                                <img className='reactionBtn pt-1 ' src={image.commentImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Comment</span>
                            </div>
                            <div className='d-flex m-0 justify-content-center align-items-center reactionOptionDivImg' onClick={() => openSharePostModel(feedData)}>
                                <img className='reactionBtn ' src={image.shareImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Share</span>
                            </div>
                            <div className='d-flex m-0 justify-content-center align-items-center reactionOptionDivImg' >
                                <img className='reactionBtn ' src={image.views} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Clicks</span>
                            </div>
                        </div>}
                        <div>
                            <span className='postEngament'> {renderFirstTime ? feedData.total_engagement : engagmentValue} Post engagement</span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        {
                            showSharePostData === true ? <>Share</> : showClickData ?  <>Clicks</> : <>Like</>
                        }
                    </Modal.Title>
                    <CloseButton onClick={closeModel} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className=''>
                                    {
                                        likeList.length > 0 ?
                                            <ul className='table-responsive' style={{ listStyle: 'none', padding: '0' }}>
                                                <li>
                                                    {
                                                        likeList.map((user, index) => {
                                                            return (
                                                                <div key={index} className='d-flex justify-content-between align-items-center likeUserDiv my-2 ' role='button' style={{ borderBottom: likeList.length === 1 ? 'none' : '1px solid #ccc6c6' }}>
                                                                    <div className='d-flex justify-content-start align-items-center'>
                                                                    {
                                                                        user.user_data.profile_picture!==undefined && user.user_data.profile_picture !== null ?
                                                                            <p className='m-0'><img className='likeProfileImagePic' src={user.user_data.profile_picture} alt='reactionCountBtn' /></p> :
                                                                            <p className='m-0'><img className='likeProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' /></p>
                                                                    }
                                                                    <p className='mx-2 my-3'>{user.user_data.full_name}</p>
                                                                    </div>
                                                                    {
                                                                        showClickData ? <p className='mx-2 my-3'>{user.view_count} {user.view_count <= 1 ? "Click" : "Clicks"}</p> : null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </li>
                                            </ul> :
                                            <div className='text-center'>
                                                {
                                                    showSharePostData === true ? <h6> No Shares</h6> : showClickData === true ? <h6>No Clicks</h6> : <h6> No likes</h6> 
                                                }
                                               </div>
                                    }
                                    {
                                        totalFeedLike.total_page > 1 && (
                                            <ReactPaginate
                                                className='pagination'
                                                breakLabel={'...'}
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={2}
                                                pageCount={totalFeedLike.total_page}
                                                previousLabel="< prev"
                                                renderOnZeroPageCount={null}
                                                containerClassName="pagination"
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                pageLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link'}
                                            />
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
            </Modal>
            <Modal
                show={commentModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Comments
                    </Modal.Title>
                    <CloseButton onClick={() => closeCommentModel()} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className='' style={{ height: '82vh' ,}}>
                                    <ul className='table-responsive' style={{ listStyle: 'none', padding: '0', height: '100%' }}>
                                        <li className='commentDataDiv' onClick={(e) => closeOpenModel(e)}>
                                            {
                                                commentList.map((user, index) => {
                                                    return (
                                                        <div className='parentComment' key={index}>
                                                            <div className='d-flex justify-content-start  commentUserDiv my-2 ' role='button'>
                                                                {
                                                                    user.users.profile_picture && user.users.profile_picture !== null ?
                                                                        <p className='m-0'><img className='commentProfileImagePic' src={user.users.profile_picture} alt='reactionCountBtn' /></p> :
                                                                        <p className='m-0'><img className='commentProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' /></p>
                                                                }
                                                                <div className='mx-2'>
                                                                    <div className='mx-2 commentData'>
                                                                        <div className='d-flex'><h6>{user.users.full_name}</h6>
                                                                            <span className='commentSpan'>{timeDifference(user.edit_date)}</span>
                                                                            <div className="optionDiv" >
                                                                                {
                                                                                    !showIndividualPostDetails ? 
                                                                                    <div>
                                                                                        <span className='threeVerticalDots' onClick={() => showOptionListD(`optionList${index}`)}><BiIcons.BsThreeDotsVertical />
                                                                                </span>
                                                                                {
                                                                                    props.permissions.includes('Post Reaction') &&
                                                                                    <ul className="optionList" id={'optionList' + index}>
                                                                                        <li className="optionIndList" onClick={() => deleteComment(user.comment_id, index, 'parent')}>Delete</li>
                                                                                        <li className="optionIndList" onClick={() => isEditComment(user.text, user.comment_id, index)}>Edit</li>
                                                                                    </ul>
                                                                                }
                                                                                    </div> : null

                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                        <p>{user.text}</p>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                <div className='d-flex  my-2 commentLikeShare'>
                                                                    <span><img className='commentReactionCountBtn' src={image.like2Image} alt='reactionCountBtn' />{user.likes_count}</span>
                                                                    {
                                                                        !showIndividualPostDetails && loginRole != "ENTERPRISE"? 
                                                                        <span className='mx-2' onClick={() => likeComment(user.comment_id, index, 'parent')} style={{ color: user.self_like === true ? '#06c' : '', fontWeight: user.self_like === true ? 'bold' : '' }}>Like</span> 
                                                                        : null
                                                                    }
                                                                   
                                                                    {
                                                                        !showIndividualPostDetails && loginRole != "ENTERPRISE"? 
                                                                        <span className='mx-2' onClick={() => replyOnComment(user.users.full_name, user.comment_id, index)}>Reply</span> : null
                                                                    }
                                                                   
                                                                    {
                                                                        user.comments_count > 0 ?
                                                                            <span className='mx-2' onClick={() => getComments(feedData.post_id, 1, 'child', showIndividualPostDetails , showIndividualPostDetails ?individualMediaId : null , user.comment_id, index)}>{user.comments_count} Replies</span> : null
                                                                    }
                                                                    <div>
                                                                        <span></span>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                user.child_comment.users !== undefined ?
                                                                    <div></div> : null
                                                            }
                                                            {
                                                                user.child_comment.map((child, ind) => {
                                                                    return (
                                                                        <div key={ind} className='childCommentDiv'>
                                                                            <div className='d-flex justify-content-start  my-2 ' role='button'>
                                                                                {
                                                                                    child.users.profile_picture && child.users.profile_picture !== null ?
                                                                                        <p className='m-0'><img className='commentProfileImagePic' src={child.users.profile_picture} alt='reactionCountBtn' /></p> :
                                                                                        <p className='m-0'><img className='commentProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' /></p>
                                                                                }
                                                                                <div className='mx-2'>
                                                                                    <div className='mx-2 commentData'>
                                                                                        <div className='d-flex'><h6>{child.users.full_name}</h6>
                                                                                            <span className='commentSpan'>{timeDifference(child.edit_date)}</span>
                                                                                            {
                                                                                                !showIndividualPostDetails && loginRole != "ENTERPRISE"? 
                                                                                                <div className="optionDiv">
                                                                                                <span className='threeVerticalDots' onClick={() => showOptionListD(`optionList${+index}${ind}`)}><BiIcons.BsThreeDotsVertical />
                                                                                                </span>
                                                                                                <ul className="optionList" id={'optionList' + index + ind}>
                                                                                                    <li className="optionIndList" onClick={() => deleteComment(child.comment_id, index, 'child', ind)}>Delete</li>
                                                                                                    <li className="optionIndList" onClick={() => isEditComment(child.text, child.comment_id, index, ind)}>Edit</li>
                                                                                                </ul>
                                                                                            </div>: null
                                                                                            }
                                                                                            
                                                                                        </div>
                                                                                        <p>{child.text}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='d-flex  my-2 commentLikeShare'>
                                                                                <span><img className='commentReactionCountBtn' src={image.like2Image} alt='reactionCountBtn' />{child.likes_count}</span>
                                                                                {
                                                                                    loginRole != "ENTERPRISE" ? 
                                                                                    <span className='mx-2' onClick={() => likeComment(child.comment_id, index, 'child')} style={{ color: child.self_like === true ? '#06c' : '', fontWeight: child.self_like === true ? 'bold' : '' }}>Like</span> : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </li>
                                        <li className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'></p>
                                            {
                                                props.permissions.includes('Post Reaction') &&
                                                <div>
                                                    {
                                                        parentsData.name !== '' ?
                                                            <span className='replyToDiv'>Replying to <span className='replyToDivName'>{parentsData.name}</span>. <span className='replyToDivCancelBtn' onClick={() => cancelBtnComment()}>cancel</span></span> : null
                                                    }
                                                    {
                                                        editComment === true ?
                                                            <span className='cancelEditBtn replyToDivCancelBtn' onClick={() => isCancelEditComment()} >Cancel edit</span> : null
                                                    }
                                                    {   !showIndividualPostDetails ? 
                                                        <div>
                                                            {
                                                        editComment === false ?
                                                            <div className='d-flex align-items-center'>
                                                                <textarea
                                                                    name="searchValue" className='commentTextArea'
                                                                    id='commentTextInputBox'
                                                                    rows="2" cols="100"
                                                                    placeholder="Write a comment...."
                                                                    value={isCommentText}
                                                                    onChange={(e) => setIsCommentText(e.target.value)}
                                                                    onKeyDown={() =>
                                                                        changeHeightTextArea('#commentTextInputBox')}
                                                                >
                                                                </textarea>
                                                                <div className='text-center' onClick={() => commentOnPost(feedData.post_id, parentsData.parentId)} style={{ cursor: 'pointer' }}>
                                                                    <img className='commentSendBtn' src={image.sendMessageImage} alt='reactionBtn' />
                                                                </div>
                                                            </div> :
                                                            <div className='d-flex align-items-center'>
                                                                <textarea
                                                                    name="searchValue" className='commentTextArea'
                                                                    id='editCommentTextInputBox'
                                                                    rows="2" cols="100"
                                                                    placeholder="Edit a comment...."
                                                                    value={isEditCommentText}
                                                                    onChange={(e) => setIsEditCommentText(e.target.value)}
                                                                    onKeyDown={() =>
                                                                        changeHeightTextArea('#editCommentTextInputBox')}
                                                                >
                                                                </textarea>
                                                                <div className='text-center' onClick={() => editCommentOnPost()} style={{ cursor: 'pointer' }}>
                                                                    <img className='commentSendBtn' src={image.sendMessageImage} alt='reactionBtn' />
                                                                </div>
                                                            </div>}
                                                            </div> : null
                                                    }
                                                </div>
                                            }

                                        </li>
                                    </ul>
                                    {
                                        totalFeedLike.total_page > 1 && (
                                            <ReactPaginate
                                                className='pagination'
                                                breakLabel={'...'}
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={2}
                                                pageCount={totalFeedLike.total_page}
                                                previousLabel="< prev"
                                                renderOnZeroPageCount={null}
                                                containerClassName="pagination"
                                                activeClassName={'active'}
                                                pageClassName={'page-item'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link'}
                                                pageLinkClassName={'page-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link'}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link'}
                                            />
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
            </Modal>
            <Modal
                show={tagUserModalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        User List
                    </Modal.Title>
                    <CloseButton onClick={() => closeUserListModel()} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div className='' style={{ height: '82vh' }}>
                                    <ul className='table-responsive' style={{ listStyle: 'none', padding: '0', height: '100%' }}>
                                        <li className='userListDataDiv'>
                                            {
                                                tagUserList.map((user, index) => {
                                                    return (
                                                        <div className='parentComment' key={index}>
                                                            <div className='d-flex justify-content-start  commentUserDiv my-2 ' role='button'>
                                                                {
                                                                    user.tagged_user_data.profile_picture && user.tagged_user_data.profile_picture !== null ?
                                                                        <p className='m-0'><img className='tagProfileImagePic' src={user.tagged_user_data.profile_picture} alt='reactionCountBtn' /></p> :
                                                                        <p className='m-0'><img className='tagProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' /></p>
                                                                }
                                                                <div className='mx-2'>
                                                                    <div className='mx-2 tagListData'>
                                                                        <div className='d-flex'><h6>{user.tagged_user_data.full_name}</h6></div>
                                                                        <p>{user.tagged_user_data.primary_speciality}</p>
                                                                    </div>
                                                                    <div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
            </Modal>
            <Modal
                show={sharePostModel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Share Post
                    </Modal.Title>
                    <CloseButton onClick={() => closeSharePostModel()} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12}>
                            {
                                sharePostModelData !== '' ?
                                    sharePostModelData.shared_post !== true ?
                                        <div className='px-2 justify-content-between '>
                                            <div className='align-items-center mb-2' style={{ width: '98%' }}>
                                                <div className='d-flex align-items-center mb-2'>
                                                    {
                                                        profilePicture === 'null' ?
                                                            <span>
                                                                <img className='shareProfileImagePic' src={image.porfilePicture} alt='postProfileImagePicasdas' />
                                                            </span> :
                                                            <span>
                                                                <img className='shareProfileImagePic' src={profilePicture} alt='postProfileImagePicassad' />
                                                            </span>
                                                    }

                                                    <h6 className='mb-1 mx-2'>{fullName}</h6>
                                                    <DropdownButton id="dropdown-basic-button px-1" className='mx-2' title={sharePostType}>
                                                        <Dropdown.Item onClick={() => setSharePostType('General')} >General</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setSharePostType('Channel')} >Channel</Dropdown.Item>
                                                    </DropdownButton>
                                                        {
                                                            sharePostType === 'Channel' ?
                                                                <DropdownButton id="dropdown-basic-button px-1" className=' mx-2 dropDownOfChannelList' title={JSON.stringify(selectChannel) === '{}' ? "Channels" : selectChannel.channel_name}>
                                                                    {channelsData && channelsData.length > 0 && channelsData.map((item, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <Dropdown.Item onClick={() => setSelectChannel(item)} >{item.channel_name}</Dropdown.Item>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </DropdownButton> : null
                                                        }
                                                    <Button 
                                                        style={{marginLeft:'auto'}}
                                                        onClick={()=>generatePostLink(sharePostModelData.post_id)}
                                                    >
                                                        Share Link
                                                    </Button>
                                                </div>
                                                <div>
                                                    <textarea
                                                        name="searchValue" className='sharePostInputBox'
                                                        id='sharePostTextInputBox'
                                                        rows="2" cols="200"
                                                        placeholder="What's in your mind"
                                                        value={isUploadText}
                                                        onChange={(e) => setIsUploadText(e.target.value)}
                                                        onKeyDown={changeHeightTextAreaShareModel('#sharePostTextInputBox')}
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='sharePostData'>
                                                <div className='profileImagePicDivPost d-flex px-2 justify-content-between '>
                                                    <div className='d-flex' style={{ width: '92%' }}>
                                                        {
                                                            sharePostModelData.user.profile_picture !==undefined && sharePostModelData.user.profile_picture === null ?
                                                                <span>
                                                                    <img className='postProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                                </span> :
                                                                <span>
                                                                    <img className='postProfileImagePic' src={sharePostModelData.user.profile_picture} alt='postProfileImagePic' />
                                                                </span>
                                                        }
                                                        <div className='mx-2' style={{ width: '92%' }}>
                                                            <div className='d-flex align-items-center' style={{ flexWrap: 'wrap' }}>
                                                                <h6 className='mb-1'>{sharePostModelData.user.full_name}{sharePostModelData.is_sponsored && sharePostModelData.is_sponsored === true ? <span className='mx-2'>(Sponsored)</span> : null}</h6>
                                                                {/* {
                                                                    sharePostModelData.channel_id !== null ?
                                                                        <div className='mx-1 d-flex align-items-center'> <h6 className='mb-1'>{`-> ${sharePostModelData.channel_data.channel_name} channel`}</h6></div> : null
                                                                } */}
                                                                {
                                                                    sharePostModelData.tag_users && sharePostModelData.tag_users.length > 0 ?
                                                                        <div className='mx-1 d-flex align-items-center'>
                                                                            <div className='d-flex align-items-center mb-1' >
                                                                                <span className='mb-0 mx-1'> with </span> <h6 className='mb-0' > {sharePostModelData.tag_users[0].user_name} </h6>
                                                                            </div>
                                                                            {sharePostModelData.tag_users.length > 1 ?
                                                                                <div className='d-flex align-items-center mb-1 mx-2' >
                                                                                    <span onClick={() => openUserListModel(sharePostModelData.post_id)} style={{ cursor: 'pointer' }}>and {sharePostModelData.tag_users.length - 1} others</span>
                                                                                </div> : null
                                                                            }
                                                                        </div> : null
                                                                }
                                                            </div>
                                                            <p className='postTiming m-0 p-0'>{timeDifferenceNew}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    sharePostModelData.text === null ?
                                                        null : <div dangerouslySetInnerHTML={{ __html: newlinesTexts(sharePostModelData.text) }} />
                                                }
                                                {
                                                    sharePostModelData.topic_tags !== null ?
                                                        sharePostModelData.topic_tags.map((tag, indexOfTag) => {
                                                            return (
                                                                <span key={indexOfTag}>
                                                                    <span className='text mx-1 topicTagName'>#{tag.name}</span>
                                                                </span>
                                                            )
                                                        }
                                                        ) : null
                                                }
                                                {
                                                    sharePostModelData.media_data.length !== 0 ?
                                                        sharePostModelData.media_data.map((media, ind) => {
                                                            {
                                                                return (
                                                                    <div key={ind}>{
                                                                        media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC"?
                                                                            <div className='postMediaData mt-2'>
                                                                                <a href={media.src} target="_blank" rel="noreferrer"><img className='postImage' src={media.src} alt='postImage' /></a>

                                                                            </div> : null
                                                                    }
                                                                        {
                                                                            media.type === "video/mp4" ?
                                                                                <div className='postMediaData mt-2'>
                                                                                    <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen 
                                                                                    sandbox="">
                                                                                    </iframe>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            media.type === "audio/mpeg" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <audio controls className='w-100'>
                                                                                        <source src={media.src} type="audio/mp3" />
                                                                                        <source src={media.src} type="audio/mpeg" />
                                                                                        <source src={media.src} type="audio/mpg " />
                                                                                    </audio>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            media.type === "application/pdf" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <embed src={media.src} className='w-100' height="400px" />
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            media.type === "application/msword" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div> :
                                        <div >
                                            <div className='d-flex align-items-center mb-2' style={{ width: '92%' }}>
                                                {
                                                    profilePicture === 'null' ?
                                                        <span>
                                                            <img className='shareProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                        </span> :
                                                        <span>
                                                            <img className='shareProfileImagePic' src={profilePicture} alt='postProfileImagePic' />
                                                        </span>
                                                }

                                                <h6 className='mb-1 mx-2'>{fullName}</h6>
                                                <DropdownButton id="dropdown-basic-button px-1" className='mx-2' title={sharePostType}>
                                                    <Dropdown.Item onClick={() => setSharePostType('General')} >General</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => setSharePostType('Channel')} >Channel</Dropdown.Item>
                                                </DropdownButton>
                                                {
                                                    sharePostType === 'Channel' ?
                                                        <DropdownButton id="dropdown-basic-button px-1" className=' mx-2 dropDownOfChannelList' title={JSON.stringify(selectChannel) === '{}' ? "Channels" : selectChannel.channel_name}>
                                                            {channelsData && channelsData.length > 0 && channelsData.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <Dropdown.Item onClick={() => setSelectChannel(item)} >{item.channel_name}</Dropdown.Item>
                                                                    </div>
                                                                )
                                                            })}
                                                        </DropdownButton> : null
                                                }
                                                <Button 
                                                        style={{marginLeft:'auto'}}
                                                        onClick={()=>generatePostLink(sharePostModelData.post_id)}
                                                    >
                                                        Share Link
                                                </Button>
                                            </div>
                                            <div>
                                                <textarea
                                                    name="searchValue" className='sharePostInputBox'
                                                    id='sharePostTextInputBox'
                                                    rows="2" cols="200"
                                                    placeholder="What's in your mind"
                                                    value={isUploadText}
                                                    onChange={(e) => setIsUploadText(e.target.value)}
                                                    onKeyDown={changeHeightTextAreaShareModel('#sharePostTextInputBox')}
                                                >
                                                </textarea>
                                            </div>
                                            <div className='sharePostData'>
                                                {
                                                    feedData.root_post_data !== null && !feedData.root_post_data.is_deleted && feedData.root_post_data.is_deleted === false ?
                                                        <div className='d-flex' style={{ width: '92%' }}>
                                                            <>
                                                                {
                                                                    feedData.root_post_data.user && feedData.root_post_data.user.profile_picture!==undefined && feedData.root_post_data.user.profile_picture === null ?
                                                                        <span>
                                                                            <img className='postProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                                        </span> :
                                                                        <span>
                                                                            <img className='postProfileImagePic' src={feedData.root_post_data.user.profile_picture} alt='postProfileImagePic' />
                                                                        </span>
                                                                }
                                                            </>
                                                            <div className='mx-2' style={{ width: '92%' }}>
                                                                <div className='d-flex align-items-center' style={{ flexWrap: 'wrap' }}>
                                                                    <h6 className='mb-1'>{feedData.root_post_data.user.full_name}{feedData.root_post_data.is_sponsored === true ? <span className='mx-2'>(Sponsored)</span> : null}</h6>
                                                                    {/* {
                                                                        feedData.root_post_data.channel_id !== null ?
                                                                            <div className='mx-1 d-flex align-items-center'> <h6 className='mb-1'>{`-> ${feedData.channel_data.channel_name} channel`}</h6></div> : null
                                                                    } */}
                                                                    {
                                                                        feedData.root_post_data.tag_users !== null && feedData.root_post_data.tag_users.length > 0 ?
                                                                            <div className='mx-1 d-flex align-items-center'>
                                                                                <div className='d-flex align-items-center mb-1' >
                                                                                    <span className='mb-0 mx-1'> with </span> <h6 className='mb-0' > {feedData.root_post_data.tag_users[0].user_name} </h6>
                                                                                </div>
                                                                                {feedData.root_post_data.tag_users.length > 1 ?
                                                                                    <div className='d-flex align-items-center mb-1 mx-2' >
                                                                                        <span onClick={() => openUserListModel(feedData.share_details.root_post_id)} style={{ cursor: 'pointer' }}>and {feedData.root_post_data.tag_users.length - 1} others</span>
                                                                                    </div> : null
                                                                                }
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                                <p className='postTiming m-0 p-0'>{timeDifferenceNew}</p>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    feedData.text !== null ?
                                                        <>
                                                            {
                                                                feedData.root_post_data.is_deleted !== true ?

                                                                    <div dangerouslySetInnerHTML={{ __html: newlinesTexts(feedData.text) }} /> :
                                                                    <h5 style={{ textAlign: 'center', color: 'steelblue' }}>
                                                                        Post unavailable
                                                                    </h5>
                                                            }
                                                        </> : null

                                                }
                                                {
                                                    feedData.root_post_data.topic_tags !== null && feedData.root_post_data.is_deleted !== true ?
                                                        feedData.root_post_data.topic_tags.map((tag, indexOfTag) => {
                                                            return (
                                                                <span key={indexOfTag}>
                                                                    <span className='text mx-1 topicTagName'>#{tag.name}</span>
                                                                </span>
                                                            )
                                                        }
                                                        ) : null
                                                }
                                                {
                                                    feedData.media_data.length !== 0  && feedData.root_post_data.is_deleted !== true ?
                                                        feedData.media_data.map((media, ind) => {
                                                            {
                                                                return (
                                                                    <div key={ind}>{
                                                                        media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC"?
                                                                            <div className='postMediaData mt-2'>
                                                                                <a href={media.src} target="_blank" rel="noreferrer"><img className='postImage' src={media.src} alt='postImage' /></a>

                                                                            </div> : null
                                                                    }
                                                                        {
                                                                            media.type === "video/mp4" ?
                                                                                <div className='postMediaData mt-2'>
                                                                                    <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen 
                                                                                    sandbox="">
                                                                                    </iframe>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            media.type === "audio/mpeg" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <audio controls className='w-100'>
                                                                                        <source src={media.src} type="audio/mp3" />
                                                                                        <source src={media.src} type="audio/mpeg" />
                                                                                        <source src={media.src} type="audio/mpg " />
                                                                                    </audio>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            media.type === "application/pdf" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <embed src={media.src} className='w-100' height="400px" />
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            media.type === "application/msword" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    : null
                            }

                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => sharePost(sharePostModelData)}>Post</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editPostModel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Edit Post
                    </Modal.Title>
                    <CloseButton onClick={() => closeEditPostModel()} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xxl={12} xl={12} lg={12} md={12}>
                            {
                                !editPostData.shared_post &&
                                <div className='py-2 d-flex align-items-center justify-content-center uploadAdminPostInputDivRow' xxl={12} xl={12} lg={12} md={4}>
                                    <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isImageUpload === true ? '#389ffe5c' : '' }}>
                                        <img className='mx-2' src={image.galleryImage} style={{ width: '1.4em' }} alt='galleryImage' />
                                        <input type="file" id="uploadImageInputDiv" name="avatar" accept=" image/png, image/jpeg" multiple onChange={(event) => uploadFile(event.target.files, 'image')} />
                                        <span className='uploadOptionDivName'>Photo</span>
                                    </div>
                                    <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isAudioUpload === true ? '#389ffe5c' : '' }}>
                                        <img className='mx-2' src={image.audioImage} alt='audioImage' style={{ width: '1.4em' }} />
                                        <input type="file" id="uploadAudioInputDiv" name="avatar" accept="audio/mp3, audio/mpeg,audio/mpg" onChange={(event) => uploadFile(event.target.files, 'audio')} />
                                        <span className='uploadOptionDivName'>Audio</span>
                                    </div>
                                    <div className='d-flex justify-content-center AdminUploadOptionDiv' style={{ background: isFileUpload === true ? '#389ffe5c' : '' }}>
                                        <img className='mx-2' src={image.attachmentIconImage} alt='audioImage' style={{ width: '1.4em' }} />
                                        <input type="file" id="uploadFileInputDiv" name="avatar" accept="application/pdf, application/vnd.ms-excel" onChange={(event) => uploadFile(event.target.files, 'file')} />
                                        <span className='uploadOptionDivName'>File</span>
                                    </div>
                                    <div className='p-0 AdminUploadOptionVideoMainDiv '>
                                        <Form className='AdminUploadOptionVideoDiv'>
                                            <Form.Group style={{ display: showProgress ? "none" : "flex" }} controlId="" className="adminUploadVideoForm">
                                                <Form.Control type="file" id="" style={{ padding: "0px" }} size="sm" accept="video/mp4" onChange={(e) => { getFileContext(e) }} />
                                                <img className='mx-2' src={image.videoImage} alt='videoImage' style={{ width: '1.2em' }} />
                                                <Form.Label className="uploadOptionDivName mb-0">Video</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{ display: showProgress ? "block" : "none", height: '100%', borderRadius: '1rem' }}>
                                                {progressInstance}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                    {
                                        editPostData.post_type !== 'channel' &&
                                        <div className='sponsoredBtn d-flex px-3 py-0' style={{ width: '100%' }} >
                                            <DropdownButton id="dropdown-basic-button px-1 " title={isPostValue.length > 10 ? isPostValue.slice(0, 10) + '...' : isPostValue}>
                                                {
                                                    postValues && postValues.length > 0 && postValues.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Dropdown.Item className={isPostValue === item ? 'active' : ''} onClick={() => postValueSelected(item)} >{item}</Dropdown.Item>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                            <Button className='adminPostFIlterButton' onClick={() => topicTagPressed()}>Topic Tag</Button>
                                            <DropdownButton id="dropdown-basic-button px-1" title={JSON.stringify(selectedAttribute) === '{}' ? "Attributes" : selectedAttribute.category_title.length > 10 ? selectedAttribute.category_title.slice(0, 10) + '...' : selectedAttribute.category_title}>
                                                {/* <Dropdown.Item onClick={() => setSelectedAttribute({})} >Attributes</Dropdown.Item> */}
                                                {postAttribute && postAttribute.length > 0 && postAttribute.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <Dropdown.Item className={selectedAttribute === item ? 'active' : ''} onClick={() => attributeSelected(item)} >{item.category_title}</Dropdown.Item>
                                                        </div>
                                                    )
                                                })}
                                            </DropdownButton>
                                            <Button className='adminPostFIlterButton' onClick={() => setEditFiltermodalShow(true)}>Filter</Button>

                                        </div>
                                        
                                    }
                                </div>
                            }
                            {
                                editPostData !== '' ?
                                    editPostData.shared_post !== true ?
                                        <div className='px-2 justify-content-between '>
                                            <div className='sharePostData'>
                                                <div className='profileImagePicDivPost d-flex px-2 justify-content-between '>
                                                    <div className='d-flex' style={{ width: '92%' }}>
                                                        <>
                                                            {
                                                                editPostData.user.profile_picture!==undefined && editPostData.user.profile_picture === null ?
                                                                    <span>
                                                                        <img className='postProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                                    </span> :
                                                                    <span>
                                                                        <img className='postProfileImagePic' src={editPostData.user.profile_picture} alt='postProfileImagePic' />
                                                                    </span>
                                                            }
                                                        </>
                                                        <div className='mx-2' style={{ width: '92%' }}>
                                                            <div className='d-flex align-items-center' style={{ flexWrap: 'wrap' }}>
                                                                <h6 className='mb-1'>{editPostData.user.full_name}</h6>
                                                                <>
                                                                    {
                                                                        editPostData.tag_users && editPostData.tag_users.length > 0 ?
                                                                            <div className='mx-1 d-flex align-items-center'>
                                                                                <div className='d-flex align-items-center mb-1' >
                                                                                    <span className='mb-0 mx-1'> with </span> <h6 className='mb-0' > {editPostData.tag_users[0].user_name} </h6>
                                                                                </div>
                                                                                {editPostData.tag_users.length > 1 ?
                                                                                    <div className='d-flex align-items-center mb-1 mx-2' >
                                                                                        <span onClick={() => openUserListModel(editPostData.post_id)} style={{ cursor: 'pointer' }}>and {sharePostModelData.tag_users.length - 1} others</span>
                                                                                    </div> : null
                                                                                }
                                                                            </div> : null
                                                                    }
                                                                </>
                                                            </div>
                                                            <p className='postTiming m-0 p-0'>{editPostData.is_sponsored && editPostData.is_sponsored === true ? <strong style={{fontSize:'1rem'}} className='mx-2'>Sponsored</strong> : null}{timeDifferenceNew}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    editPostData.text !== undefined &&
                                                    <div className='mt-3'>
                                                        <textarea
                                                            name="searchValue" className='editPostInputBox'
                                                            id='editPostTextInputBox'
                                                            rows="2" cols="200"
                                                            placeholder="What's in your mind"
                                                            value={editPostData.text}
                                                            onChange={(e) => setEditPostData({ ...editPostData, text: e.target.value })}
                                                            onKeyDown={changeHeightTextAreaShareModel('#editPostTextInputBox')}
                                                        >
                                                        </textarea>
                                                    </div>
                                                }
                                                <>
                                                    {
                                                        selectedTopicTags !== null ?
                                                        selectedTopicTags.map((tag, indexOfTag) => {
                                                                return (
                                                                    <span key={indexOfTag}>
                                                                        <span className='text mx-1 topicTagName'>#{tag.label}</span>
                                                                    </span>
                                                                )
                                                            }
                                                            ) : null
                                                    }
                                                </>
                                                <>
                                                    {
                                                        mideaList.length !== 0 ?
                                                            mideaList.map((media, ind) => {
                                                                {
                                                                    return (
                                                                        <div key={ind}>{
                                                                            media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC"?
                                                                                <div className='postMediaData mt-2'>
                                                                                    {media.src && <a href={media.src} target="_blank" rel="noreferrer"><img className='editPostImage' src={media.src} alt='editPostImage' /></a>}
                                                                                    {media.url && <a href={media.url} target="_blank" rel="noreferrer"><img className='editPostImage' src={media.url} alt='editPostImage' /></a>}
                                                                                    <span className='removeItem' onClick={() => removeMedia(ind)}><AiIcons.AiFillDelete /></span>
                                                                                </div> : null
                                                                        }
                                                                            {
                                                                                media.type === "video/mp4" ?
                                                                                    <div className='postMediaData mt-2'>
                                                                                        {
                                                                                            media.src &&
                                                                                            <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen
                                                                                            sandbox="">
                                                                                            </iframe>
                                                                                        }
                                                                                        {
                                                                                            media.url &&
                                                                                            <iframe title="video" className='postVideo w-100' src={media.url + '?autoplay=0' } frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                                                                            sandbox=""
                                                                                             allowFullScreen
                                                                                            >
                                                                                            </iframe>
                                                                                        }
                                                                                        <span className='removeItem' onClick={() => removeMedia(ind)}><AiIcons.AiFillDelete /></span>
                                                                                    </div>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                media.type === "audio/mpeg" ?
                                                                                    <div className='mt-2 postMediaData'>
                                                                                        {
                                                                                            media.src &&
                                                                                            <audio controls className='w-100'>
                                                                                                <source src={media.src} type="audio/mp3" />
                                                                                                <source src={media.src} type="audio/mpeg" />
                                                                                                <source src={media.src} type="audio/mpg " />
                                                                                            </audio>
                                                                                        }
                                                                                        {
                                                                                            media.url &&
                                                                                            <audio controls className='w-100'>
                                                                                                <source src={media.url} type="audio/mp3" />
                                                                                                <source src={media.url} type="audio/mpeg" />
                                                                                                <source src={media.url} type="audio/mpg " />
                                                                                            </audio>
                                                                                        }

                                                                                        <span className='removeItem' onClick={() => removeMedia(ind)}><AiIcons.AiFillDelete /></span>
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                media.type === "application/pdf" ?
                                                                                    <div className='mt-2 postMediaData'>
                                                                                        {
                                                                                            media.src &&
                                                                                            <embed src={media.src} className='w-100' height="400px" />
                                                                                        }
                                                                                        {
                                                                                            media.url &&
                                                                                            <embed src={media.url} className='w-100' height="400px" />
                                                                                        }
                                                                                        <span className='removeItem' onClick={() => removeMedia(ind)}><AiIcons.AiFillDelete /></span>
                                                                                    </div> : null
                                                                            }
                                                                            {
                                                                                media.type === "application/msword" ?
                                                                                    <div className='mt-2 postMediaData'>
                                                                                        {
                                                                                            media.src &&
                                                                                            <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                                        }
                                                                                        {
                                                                                            media.url &&
                                                                                            <a className='btn btn-primary my-2' target='_blank' href={media.url}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                                        }
                                                                                        <span className='removeItem' onClick={() => removeMedia(ind)}><AiIcons.AiFillDelete /></span>
                                                                                    </div> : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                            : null
                                                    }
                                                </>
                                            </div>
                                        </div> :
                                        <div >

                                            <div className='d-flex align-items-center mb-2' style={{ width: '92%' }}>
                                                {
                                                    profilePicture === 'null' ?
                                                        <span>
                                                            <img className='shareProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                        </span> :
                                                        <span>
                                                            <img className='shareProfileImagePic' src={profilePicture} alt='postProfileImagePic' />
                                                        </span>
                                                }

                                                <h6 className='mb-1 mx-2'>{fullName}</h6>
                                            </div>
                                            <div className='mt-1'>
                                                <textarea
                                                    name="searchValue" className='editPostInputBox'
                                                    id='editPostTextInputBox'
                                                    rows="2" cols="200"
                                                    placeholder="What's in your mind"
                                                    value={editPostData.share_details.share_text}
                                                    onChange={(e) => setEditPostData({ ...editPostData, share_details: { ...editPostData.share_details, share_text: e.target.value } })}
                                                    onKeyDown={changeHeightTextAreaShareModel('#editPostTextInputBox')}
                                                >
                                                </textarea>
                                            </div>
                                            <div className='sharePostData'>
                                                {
                                                    feedData.root_post_data !== null && !feedData.root_post_data.is_deleted && feedData.root_post_data.is_deleted === false ?
                                                        <div className='d-flex' style={{ width: '92%' }}>
                                                            <>
                                                                {
                                                                    feedData.root_post_data.user && feedData.root_post_data.user.profile_picture !==undefined && feedData.root_post_data.user.profile_picture === null ?
                                                                        <span>
                                                                            <img className='postProfileImagePic' src={image.porfilePicture} alt='postProfileImagePic' />
                                                                        </span> :
                                                                        <span>
                                                                            <img className='postProfileImagePic' src={feedData.root_post_data.user.profile_picture} alt='postProfileImagePic' />
                                                                        </span>
                                                                }
                                                            </>
                                                            <div className='mx-2' style={{ width: '92%' }}>
                                                                <div className='d-flex align-items-center' style={{ flexWrap: 'wrap' }}>
                                                                    <h6 className='mb-1'>{feedData.root_post_data.user.full_name}{feedData.root_post_data.is_sponsored && feedData.root_post_data.is_sponsored === true ? <span className='mx-2'>(Sponsored)</span> : null}</h6>
                                                                    {/* {
                                                                        feedData.root_post_data.channel_id !== null ?
                                                                            <div className='mx-1 d-flex align-items-center'> <h6 className='mb-1'>{`-> ${feedData.channel_data.channel_name} channel`}</h6></div> : null
                                                                    } */}
                                                                    {
                                                                        feedData.root_post_data.tag_users !== null && feedData.root_post_data.tag_users.length > 0 ?
                                                                            <div className='mx-1 d-flex align-items-center'>
                                                                                <div className='d-flex align-items-center mb-1' >
                                                                                    <span className='mb-0 mx-1'> with </span> <h6 className='mb-0' > {feedData.root_post_data.tag_users[0].user_name} </h6>
                                                                                </div>
                                                                                {feedData.root_post_data.tag_users.length > 1 ?
                                                                                    <div className='d-flex align-items-center mb-1 mx-2' >
                                                                                        <span onClick={() => openUserListModel(feedData.share_details.root_post_id)} style={{ cursor: 'pointer' }}>and {feedData.root_post_data.tag_users.length - 1} others</span>
                                                                                    </div> : null
                                                                                }
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                                <p className='postTiming m-0 p-0'>{timeDifferenceNew}</p>
                                                            </div>
                                                        </div> : null
                                                }
                                                {
                                                    feedData.text !== null ?
                                                        <>
                                                            {
                                                                feedData.root_post_data.is_deleted !== true ?
                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: newlinesTexts(feedData.text) }} />
                                                                    </> :
                                                                    <h5 style={{ textAlign: 'center', color: 'steelblue' }}>
                                                                        Post unavailable
                                                                    </h5>
                                                            }
                                                        </> : null

                                                }
                                                {
                                                    feedData.root_post_data.topic_tags !== null && feedData.root_post_data.is_deleted !== true ?
                                                        feedData.root_post_data.topic_tags.map((tag, indexOfTag) => {
                                                            return (
                                                                <span key={indexOfTag}>
                                                                    <span className='text mx-1 topicTagName'>#{tag.name}</span>
                                                                </span>
                                                            )
                                                        }
                                                        ) : null
                                                }
                                                {
                                                    feedData.media_data.length !== 0 && feedData.root_post_data.is_deleted !== true ?
                                                        feedData.media_data.map((media, ind) => {
                                                            {
                                                                return (
                                                                    <div key={ind}>{
                                                                        media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC"?
                                                                            <div className='postMediaData mt-2'>
                                                                                <a href={media.src} target="_blank" rel="noreferrer"><img className='editPostImage' src={media.src} alt='editPostImage' /></a>

                                                                            </div> : null
                                                                    }
                                                                        {
                                                                            media.type === "video/mp4" ?
                                                                                <div className='postMediaData mt-2'>
                                                                                    <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen
                                                                                    sandbox=""
                                                                                    >
                                                                                    </iframe>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        {
                                                                            media.type === "audio/mpeg" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <audio controls className='w-100'>
                                                                                        <source src={media.src} type="audio/mp3" />
                                                                                        <source src={media.src} type="audio/mpeg" />
                                                                                        <source src={media.src} type="audio/mpg " />
                                                                                    </audio>
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            media.type === "application/pdf" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <embed src={media.src} className='w-100' height="400px" />
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            media.type === "application/msword" ?
                                                                                <div className='mt-2 postMediaData'>
                                                                                    <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                                                </div> : null
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    : null
                            }

                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => editPost(editPostData)}>Edit post</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={editFiltermodalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-between'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Filter
                    </Modal.Title>
                    <CloseButton onClick={() => { closeFilterModel() }} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                                <div>
                                    <div className='my-2'>
                                        <h6>User Type <span className="redStar">&#42;</span> </h6>
                                        <div className='d-flex'>
                                        <input type="radio" className="" checked={userType === ''} name='userType' value={''} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >None</label><br></br>
                                            <input type="radio" className="" checked={userType === 'STUDENT'} name='userType' value={'STUDENT'} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >Student</label><br></br>
                                            <input className="mx-2" type="radio" checked={userType === 'PRACTITIONER' || userType === 'DOCTOR'} name='userType' value={'PRACTITIONER'} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >Practitioner</label><br></br>
                                            <input className="mx-2" type="radio" checked={userType === 'BOTH'} name='userType' value={'BOTH'} style={{ cursor: 'pointer' }} onChange={e => setUserTypeData(e.target.value)} />
                                            <label className="mx-2" >Both</label><br></br>
                                        </div>
                                    </div>
                                    {
                                        userType === 'STUDENT' || userType === 'BOTH' ?
                                            <div className='my-1'>
                                                <h6>University </h6>
                                                <MultiSelect
                                                    options={univercityList}
                                                    value={selectUnivercityList}
                                                    onChange={setSelectUnivercityList}
                                                    labelledBy="Select"
                                                />
                                            </div> : null
                                    }
                                    {
                                        userType === 'PRACTITIONER' ||  userType === 'DOCTOR' ?
                                            <div className='my-1'>
                                                <h6>Practitioner </h6>
                                                <div className='d-flex'>
                                                    <input type="radio" className="" checked={practitionerType === 'surgeon'} name='practitioner' value={'surgeon'} style={{ cursor: 'pointer' }} onChange={e => setPractitionerType(e.target.value)} />
                                                    <label className="mx-2" >Surgeon</label><br></br>
                                                    <input className="mx-2" type="radio" checked={practitionerType === 'physician'} name='practitioner' value={'physician'} style={{ cursor: 'pointer' }} onChange={e => setPractitionerType(e.target.value)} />
                                                    <label className="mx-2" >Physician</label><br></br>
                                                    <input className="mx-2" type="radio" checked={practitionerType === 'both'} name='practitioner' value={'both'} style={{ cursor: 'pointer' }} onChange={e => setPractitionerType(e.target.value)} />
                                                    <label className="mx-2" >Both</label><br></br>
                                                </div>
                                            </div> : null
                                    }
                                    <div className='my-2' style={{ position: 'relative' }}>
                                        <h6>Province/City </h6>

                                        {
                                            provinceData.length > 0 ?
                                                <Multiselect
                                                    isObject={false}
                                                    options={provinceData}
                                                    selectedValues = {provinceCity}
                                                    onSelect={(e) => onProvinceClick(e)}
                                                    onRemove={(e) => onProvinceClick(e)}
                                                /> : <select className="form-control selectpicker searchWrapper" id="select-country" data-live-search="true">
                                                    <option key='noCity'>select city</option>
                                                </select>

                                        }
                                        <span style={{ position: 'absolute' }} className='customDropdown' >&#8964;</span>

                                    </div>
                                    <div className='my-2' style={{ position: 'relative' }}>
                                        <h6>District </h6>
                                        {
                                            provinceCityData.length > 0 ?
                                                <Multiselect
                                                    isObject={false}
                                                    options={provinceCityData}
                                                    selectedValues={district}
                                                    onSelect={(e) => setDistrict(e)}
                                                    onRemove={(e) => setDistrict(e)}
                                                /> : <select className="form-control selectpicker" id="select-country" data-live-search="true">
                                                    <option key='noCity'>select District</option>
                                                </select>

                                        }
                                        <span style={{ position: 'absolute' }} className='customDropdown'>&#8964;</span>
                                    </div>
                                    <div className='my-2'>
                                        <h6>Primary Specialties  </h6>
                                        {
                                            registrationPageData.specialities !== undefined ?
                                                <>
                                                    <MultiSelect
                                                        options={specialitiesList}
                                                        value={primarySpeciality}
                                                        onChange={setPrimarySpeciality}
                                                        labelledBy="Select"
                                                    />
                                                </> : null

                                        }
                                    </div>
                                    <div className='my-2'>
                                        <h6>Secondary Specialties  </h6>
                                        {
                                            registrationPageData.specialities !== undefined ?
                                                <>
                                                    <MultiSelect
                                                        options={specialitiesList}
                                                        value={secondarySpeciality}
                                                        onChange={setSecondarySpeciality}
                                                        labelledBy="Select"
                                                    />
                                                </> : null
                                        }
                                    </div>
                                    {
                                        userType !== 'STUDENT' ?
                                            <div className='my-2'>
                                                <h6>Workplaces  </h6>
                                                {
                                                    registrationPageData.workplaces !== undefined ?
                                                        <Multiselect
                                                            isObject={false}
                                                            selectedValues={placeOfWork}
                                                            options={registrationPageData.workplaces}
                                                            onSelect={(e) => setPlaceOfWork(e)}
                                                            onRemove={(e) => setPlaceOfWork(e)}
                                                        /> : null
                                                }
                                            </div> : null
                                    }
                                    <div>
                                        <h6>Age  </h6>
                                        <div className='d-flex'>
                                            <input type="number" className="form-control" placeholder="Min age"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={minValue}
                                                onChange={(e) => setMinValue(e.target.value)}
                                            />
                                            <input type="number" className="form-control" placeholder="Max age"
                                                aria-label="Title" aria-describedby="basic-addon1"
                                                value={maxValue}
                                                onChange={(e) => setMaxValue(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { saveAllFilterData() }}>Done</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={topicTagModel}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-start'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Add Topic Tags
                    </Modal.Title>
                    <CloseButton onClick={() => { onTopicTagDone() }} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                        <Row>
                            <div className='d-flex flex-row flex-wrap'>
                                {MultipleSpeciality}
                            </div>
                            <Col xxl={12} xl={12} lg={12} md={12}>
                            <div>
                                <MultiSelect
                                    options={topicTagList}
                                    value={selectedTopicTags}
                                    onChange={setSelectedTopicTags}
                                    labelledBy="Select Topic Tags"
                                    hasSelectAll={true}
                                
                                />
                              </div>
                            </Col>
                        </Row>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { onTopicTagDone() }}>Done</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showIndividualPostDetails}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='justify-content-start'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ color: '#2882fc', paddingLeft: '1.3em' }}>
                        Post
                    </Modal.Title>
                    <CloseButton onClick={() => { closeIndividualPostModel() }} />
                </Modal.Header>
                <Modal.Body>
                    <section>
                    <div className='postRightSideDiv'>
                    <div className='pt-2 px-2 postRightSide'>
                    {
                        indivisualPostDetails.length !== 0 && indivisualPostDetails[0].text !== null ? 
                        
                            <div className='feedDataDiv text-break' dangerouslySetInnerHTML={{ __html: newlinesTexts(indivisualPostDetails[0].text) }} />
                         : null
                    }
                        {/* <div className='d-flex mt-2 justify-content-between align-items-center  reactionCountDiv justify-content-between'>
                            <div className='d-flex align-items-center' onClick={() => showUserWhoLike(feedData.post_id, 1 , true)}>
                                <img className='reactionCountBtn' src={image.like2Image} alt='reactionCountBtn' />
                                <span className='m-0 p-0'>{kFormatter(renderFirstTime ? feedData.like_count : likeCount )}</span>
                            </div>
                            <div className='d-flex justify-content-center px-2' onClick={() => getComments(feedData.post_id, 1, 'comment', false , null)}>
                                <span className='m-0 p-0'>{kFormatter(feedData.comment_count)}</span>
                                <span className='reactionCountLabel'> Comments</span>
                            </div>
                            <div className='d-flex px-2'  onClick={() => showUserWhoshare(feedData.post_id, 1 , true)}>
                                <span className='m-0 p-0'>{kFormatter(feedData.share_count)}</span>
                                <span className='reactionCountLabel'>Share</span>
                            </div>
                            <div className='d-flex px-2' onClick={() => showUserWhoClick(feedData.post_id, 1)}>
                                <span className='m-0 p-0'>{kFormatter(feedData.view_count)}</span>
                                <span className='reactionCountLabel'>Clicks</span>
                            </div>
                        </div>
                        {props.permissions.includes('Post Reaction') && <div className='d-flex mt-2 px-2 justify-content-between reactionOptionDiv'>
                            <div className='d-flex justify-content-center align-items-center reactionOptionDivImg ' onClick={() => likePost(feedData.post_id, selfLike)} >
                                <img className='reactionBtn' src={image.likeImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName' style={{ color: selfLike === true ? 'blue' : '' }}>Like</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-center reactionOptionDivImg' onClick={() => getComments(feedData.post_id, 1, 'comment', false , null)}>
                                <img className='reactionBtn pt-1 ' src={image.commentImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Comment</span>
                            </div>
                            <div className='d-flex m-0 justify-content-center align-items-center reactionOptionDivImg' onClick={() => openSharePostModel(feedData)}>
                                <img className='reactionBtn ' src={image.shareImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Share</span>
                            </div>
                            <div className='d-flex m-0 justify-content-center align-items-center reactionOptionDivImg' >
                                <img className='reactionBtn ' src={image.views} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Clicks</span>
                            </div>
                        </div>} */}
                    {
                        indivisualPostDetails.length !== 0 ? 
                        indivisualPostDetails.map((item , id) => {
                            let media = item.media_data
                            {
                                return (
                                    <div className='mt-4'>{
                                        media.type === "image/png" || media.type === "image/jpeg" || media.type === "image/gif" || media.type === "image/webp" || media.type === "image/heic" || media.type === "image/HEIC" ?
                                            <div className='postMediaData mt-2'>
                                                 <a href={media.src} target="_blank" rel="noreferrer"> 
                                                    <img className='postImage' src={media.src} alt='postImage' />
                                                 </a> 
                                            </div> : null
                                    }
                                        {
                                            media.type === "video/mp4" ?
                                                <div className='postMediaData mt-2'  >
                                                    <iframe title="video" className='postVideo w-100' src={media.src + '?autoplay=0'} frameBorder="0" allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay" allowFullScreen
                                                    sandbox="">
                                                    </iframe>
                                                </div>
                                                : null
                                        }
                                        {
                                            media.type === "audio/mpeg" ?
                                                <div className='mt-2 postMediaData'>
                                                    <audio controls className='w-100'>
                                                        <source src={media.src} type="audio/mp3" />
                                                        <source src={media.src} type="audio/mpeg" />
                                                        <source src={media.src} type="audio/mpg " />
                                                    </audio>
                                                </div> : null
                                        }
                                        {
                                            media.type === "application/pdf" ?
                                                <div className='mt-2 postMediaData'>
                                                    <embed src={media.src} className='w-100' height="400px" />

                                                </div> : null
                                        }
                                        {
                                            media.type === "application/msword" ?
                                                <div className='mt-2 postMediaData' >
                                                    <a className='btn btn-primary my-2' target='_blank' href={media.src}>Download <img style={{ height: '1em', width: '1em', marginLeft: '0.3em' }} alt='donwload' src={image.downloadImage} /></a>
                                                </div> : null
                                        }
                        <div className='d-flex mt-2 justify-content-between align-items-center  reactionCountDiv justify-content-between'>
                            <div className='d-flex align-items-center' onClick={() => showUserWhoLike(media.media_id, 1 , false)}>
                                <img className='reactionCountBtn' src={image.like2Image} alt='reactionCountBtn' />
                                <span className='m-0 p-0'>{kFormatter(media.like_count )}</span>
                            </div>
                            <div className='d-flex justify-content-center px-2' onClick={() => getComments(feedData.post_id, 1, 'comment', true ,media.media_id )}>
                                <span className='m-0 p-0'>{kFormatter(media.comment_count)}</span>
                                <span className='reactionCountLabel'> Comments</span>
                            </div>
                            <div className='d-flex px-2'  onClick={() => showUserWhoshare(media.media_id, 1 , false)}>
                                <span className='m-0 p-0'>{kFormatter(media.share_count)}</span>
                                <span className='reactionCountLabel'>Share</span>
                            </div>
                           
                        </div>
                        {/* {props.permissions.includes('Post Reaction') && <div className='d-flex mt-2 px-2 justify-content-between reactionOptionDiv'>
                            <div className='d-flex justify-content-center align-items-center reactionOptionDivImg ' onClick={() => likePost(feedData.post_id, selfLike)} >
                                <img className='reactionBtn' src={image.likeImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName' style={{ color: selfLike === true ? 'blue' : '' }}>Like</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-center reactionOptionDivImg' onClick={() => getComments(feedData.post_id, 1, 'comment' , true , media.media_id)}>
                                <img className='reactionBtn pt-1 ' src={image.commentImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Comment</span>
                            </div>
                            <div className='d-flex m-0 justify-content-center align-items-center reactionOptionDivImg' onClick={() => openSharePostModel(feedData)}>
                                <img className='reactionBtn ' src={image.shareImage} alt='reactionBtn' />
                                <span className='uploadOptionDivName'>Share</span>
                            </div>
                           
                        </div>} */}
                                    </div>
                                )
                            }
                        }) : 
                        <div>
                            No data Found
                        </div>
                        
                    }
                    </div>
                </div>
                    </section>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default MediaGrid